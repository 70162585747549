<template>
  <v-expansion-panels>
    <VMNewContractInventorySelectBooking
      v-for="(booking, ind) in bookings" 
      :key="ind"
      :booking="booking"
      :room-type="roomType"
      :contractId="contractId"
      @selected="(payload) => emitSelected(ind, payload)"
    />
  </v-expansion-panels>
</template>

<script>
import VMNewContractInventorySelectBooking from './VMNewContractInventorySelectBooking.vue'
export default {
  components: { VMNewContractInventorySelectBooking },
  props: {
    contractId: Number,
    bookings: Array,
    roomType: Object,
  },
  methods: {
    emitSelected(index, selectedPayload) {
      this.$emit('selected', {
        index,
        booking: selectedPayload
      })
    }
  }
}
</script>