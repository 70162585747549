<template>
  <v-dialog v-model="isShow" @click:outside="closeDialog">
    <div class="frame-selection" style="width: fit-content;">
      <!-- header -->
      <header style="width: calc(100vw - 72px)">
        <p class="header-title">枠を選択して下さい</p>
        <div
          class="selected-reservations"
          v-if="selectedReservationRooms.length > 0"
        >
          <!-- choosing -->
          <div class="modal-custom_block d-inline-flex align-center mb-4">
            <h3 class="color-red mr-4">
              {{ $t('reservationCalendar.select') }}
            </h3>
            <div class="mr-4">
              <p class="modal-custom_txt mb-2">
                {{ $t('reservationCalendar.checkIn') }}
              </p>
              <p class="mb-0">
                {{
                  `${formatDate(getSelectedReservationRoomDates[0])}から${
                    selectedReservationRooms.length
                  }泊`
                }}
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.facility') }}
              </p>
              <p class="mb-0">{{ selectedFacility.name }}</p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.typeRoom') }}
              </p>
              <p class="mb-0">{{ selectedReservationRooms[0].roomTypeName }}</p>
            </div>
          </div>
          <!-- choosing -->
          <!-- header-actions -->
          <div class="header-actions">
            <div>
              <p class="font-weight-bold">利用契約</p>
              <p>法人チケット</p>
            </div>
            <v-btn
              color="error"
              class="btn-close mr-2"
              @click="
                resetReservationRooms();
                closeDialog();
              "
              ><v-icon>mdi-close</v-icon>
              {{ $t('buttons.close') }}
            </v-btn>
            <v-btn color="primary" @click.prevent="next">次へ</v-btn>
          </div>
          <!-- header-actions -->
        </div>
        <div class="flex-header px-4 mt-5">
          <v-btn @click="prevMonth" icon>
            <v-icon size="35">mdi-chevron-double-left</v-icon>
          </v-btn>
          <v-btn @click="nextMonth" icon :disabled="(new Date(toDateDefault) - new Date(fromDateDefault)) === 0">
            <v-icon size="35">mdi-chevron-double-right</v-icon>
          </v-btn>
          <v-btn
            @click="chooseToday"
            outlined
            small
            class="mr-5 ml-1"
            color="var(--text__gray)"
          >
            {{ $t('common.toDay') }}
          </v-btn>
          <!-- datetime -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon color="#000000">mdi-calendar-month</v-icon>
                <v-btn
                  v-model="fromDate"
                  v-on="on"
                  small
                  class="btn--date mr-5"
                >
                  {{ formatDate(fromDate) }}
                </v-btn>
              </div>
            </template>
            <v-date-picker
              v-model="fromDate"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              @change="fetchData"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <label class="text--small mr-1">期間</label>
          <v-select
            :items="$options.timeRange"
            item-text="text"
            item-value="value"
            v-model="rangeTime"
            @change="changeTime"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
          <!-- datetime -->
          <!-- facility -->
          <label class="ml-5 text--small mr-1">{{
            $t('common.facility')
          }}</label>
          <v-select
            :items="facilityListItem"
            v-model="idSelectedFacility"
            item-text="name"
            item-value="id"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
          <!-- facility -->
        </div>
      </header>
      <!-- header -->
      <!-- body -->
      <div
        class="t-wrapper"
        v-if="
          accommodationPriceList.length > 0 ||
          mwWeekCalendarList.length > 0 ||
          bookingPlanList.length > 0
        "
      >
        <div class="t-calendar">
          <!-- month-->
          <div class="t-calendar__header">
            <div class="t-calendar__name">
              <h3 class="text-title">
                {{ selectedFacility ? selectedFacility.name : '' }}
              </h3>
            </div>
          </div>
          <!-- month-->
          
        </div>
      </div>
      <!-- main -->
      <div class="modal-custom_block d-inline-flex align-center mb-4">
        <div class="main_calendar white mx-0 my-0">
          <!-- mwWeek -->
          <div class="mw-weeks" v-if="mwWeekCalendarList.length > 0">
            <span
              v-for="week in Object.entries(mwWeekNo)"
              :key="week[0]"
              :style="'width: calc(151px *' + week[1].length + ')'"
              >{{ week[0] }}後半</span
            >
          </div>
          <!-- mwWeek -->
          <!-- title -->
          <div class="d-flex">
            <div class="main-calendar__name">
              <!-- <h3 class="main_calendar_title">箱根強羅</h3> -->
              <h3 class="main_calendar_title">{{ $t('reservationCalendar.typeRoom') }}</h3>
            </div>
            <div class="main_calendar__header-list">
              <div
                v-for="(month, index) in getMonthsList"
                :key="index"
                :style="month.style"
              >
                {{ month.value }}
              </div>
            </div>
          </div>
          <!-- title -->
          <!-- room-type -->
          <Pass
            v-for="(item, index) in inventoryList"
            :key="item.roomTypeId"
            :metadataInventory="{
              roomTypeName: getNameRoomType(item.roomTypeId),
            }"
          >
            <div
              slot-scope="{ metadataInventory }"
              :class="`calendar-custom ${index >= 1 ? 'mt-5' : ''}`"
            >
              <div :style="'background-color: #' + getBgRoom(item.roomTypeId)">
                <p style="margin-left: 10px"></p>
              </div>
              <!-- room-name -->
              <div class="calendar-custom_block">
                <div class="calendar-custom_item">
                  {{ metadataInventory.roomTypeName }}
                </div>
                <!-- mwWeek -->
                <template v-if="mwWeekCalendarList.length > 0">
                  <div class="calendar-custom_item">MWPW週番号</div>
                  <div class="calendar-custom_item">MW</div>
                </template>
                <!-- mwWeek -->
              </div>
              <!-- room-name -->
              <!-- calendar -->
              <div
                class="calendar-custom_block"
                v-for="(infoCalendar, index) in item.calendar"
                :key="index"
              >
                <div
                  class="calendar-custom_item"
                  v-text="getDate(infoCalendar.date)"
                  :class="{
                    'color-red':
                      getDate(infoCalendar.date).substr(3, 4) == '(日)',
                  }"
                ></div>
                <!-- mwWeek -->
                <template v-if="mwWeekCalendarList.length > 0">
                  <div class="calendar-custom_item">
                    <span></span>
                  </div>
                  <div
                    class="calendar-custom_item"
                    :style="'background-color: #FFC3B1'"
                  >
                    <span>{{ getMwRank(infoCalendar.date) }}</span>
                  </div>
                </template>
                <!-- mwWeek -->
                <!-- reservations -->
                <div
                  v-for="(reservation, reservationIndex) in infoCalendar.item"
                  :key="reservationIndex"
                >
                  <div
                    class="
                      calendar-custom_item
                      h-auto
                      py-0
                      my-0
                      custom-div-farth
                    "
                    :style="'background-color: rgb(247 116 116)'"
                    v-if="reservation.rooms !== 0"
                  >
                    <template v-if="reservation.bookings.length > 0">
                      <div
                        class="calendar-custom_item"
                        v-for="booking in reservation.bookings"
                        :key="booking.id"
                        :set="
                          (isDuplicate = checkDuplicateBooking(
                            reservation.bookings,
                            booking.room.name,
                          ))
                        "
                      >
                        <div>
                          <span class="calendar-custom_item_txt">
                            {{ booking.room.name }}
                          </span>
                        </div>
                      </div>
                    </template>
                    <Pass
                      v-for="roomIndex in Math.abs(reservation.rooms)"
                      :key="roomIndex"
                      :metadata="{
                        index: Number(`${reservationIndex}${roomIndex}`),
                        isSelected: isSeletedCell(
                          item.roomTypeId,
                          infoCalendar.date,
                          Number(`${reservationIndex}${roomIndex}`),
                        ),
                        isValid: isValidCell(
                          item.roomTypeId,
                          infoCalendar.date,
                          Number(`${reservationIndex}${roomIndex}`),
                        ),
                      }"
                    >
                      <div
                        slot-scope="{ metadata }"
                        :class="[
                          'calendar-custom_item',
                          {
                            'calendar-custom_selected': metadata.isSelected,
                            'cursor-pointer': metadata.isValid,
                          },
                        ]"
                        @click="
                          metadata.isValid
                            ? selectReservationRoom(
                                item.roomTypeId,
                                reservation.inventoryTypeId,
                                metadataInventory.roomTypeName,
                                infoCalendar.date,
                                metadata.index,
                                metadata.isSelected
                              )
                            : null
                        "
                      >
                        <span class="calendar-custom_item_txt"></span>
                      </div>
                    </Pass>
                  </div>
                </div>
                <!-- reservations -->
              </div>
              <!-- calendar -->
            </div>
          </Pass>
          <!-- room-type -->
        </div>
      </div>
      <!-- main -->
      <!-- body -->
    </div>
  </v-dialog>
</template>

<script>
import { mixin } from './frameSelectionBooking/index.mixin';

export default {
  name: 'FrameSelectionBooking',
  mixins: [mixin],
  props: {
    fromDateDefault: String,
    toDateDefault: String,
    facilityIdList: Array,
    defaultFacilityId: {
      type: Number,
    },
    isShowFrameSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  watch: {
    isShowFrameSelection(value) {
      this.isShow = value;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
      this.$emit('update:isShowFrameSelection', false);
    },
    next() {
      this.closeDialog();
      this.$emit('getData', {
        facility: this.selectedFacility,
        selectedReservationRooms: this.selectedReservationRooms,
      });
    },
  },
};
</script>

<style lang="scss">
@import './frameSelection/calendar.scss';
</style>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0 !important;
}

div.frame-selection {
  background-color: white;
  padding: 8px 0;

  header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    padding: 8px;

    .header-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .header-actions {
      box-shadow: 0px 2px 3px #00000029;
      padding: 10px;
      border-radius: 3px;
      background-color: #fafafa;
      display: flex;
      text-align: center;
      margin-bottom: 16px;
      & > div {
        width: 151px;
        margin-right: 44px;
        text-align: left;
      }
    }
  }

  .calendar-custom_block {
    width: 160px;
  }

  .mw-weeks {
    width: fit-content;
    height: 25.99px;
    display: flex;
    position: relative;
    left: 161px;
    top: 77.87px;
    line-height: 25.99px;
    text-align: center;
    z-index: 1;
    span {
      width: 152px;
      background-color: white;
      border: 1px solid #dee2e6;
      border-left: 0;
    }
  }

  div.selected-reservations {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 4px;
  }

  .calendar-custom_item:last-child {
    border-bottom: 1px solid #dee2e6;
    padding: 0 !important;
  }

  .calendar-custom_item_txt {
    margin: 0 10px;
    font-weight: bold;
  }

  .custom-div-farth {
    border-bottom: none !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .calendar-custom_selected {
    box-shadow: 0px 1px 2px #00000029;
    border: 2px solid #ff0000 !important;
  }
}
</style>

