export function getAdditionalChargeType (type) {
    switch (type) {
      case 'RoomStay':
        return '1室1滞在'
      case 'PersonStay':
        return '1名1滞在'
      case 'RoomDay':
        return '1室1泊'
      case 'PersonDay':
        return '1名1泊'
      default:
        return ''
    }
  }
