// components
import BookingPlanItem from './components/BookingPlanItem';
import AccomItem from './components/AccomItem';
// apollo
import { apolloClient } from '@/plugins/apollo';
// api
import { ACCOMMODATION_PRICE_LIST } from '@/api/graphql/accommodation/getAccomodationPriceList';
import { FACLILITY_LIST } from '@/api/graphql/corporateTicket/new-ticket';
// utils
import { formatDate, formatMonth } from '@/constants/functions';
import {
  addMonths,
  getDate,
  getAllDateInBetween2Dates,
  getTotalDaysInMonth,
  getDayInMonth,
} from '@/utils/dateUtil';
// vuex
import { mapMutations } from 'vuex';
// mixin
import { bookingMixin } from './booking.mixin';
import gql from 'graphql-tag';

export const mixin = {
  mixins: [bookingMixin],
  components: {
    BookingPlanItem,
    AccomItem,
  },
  name: 'FeesAndPoints',
  timeRange: [
    {
      text: '1ヶ月',
      value: 1,
    },
    {
      text: '3ヶ月',
      value: 3,
    },
    {
      text: '6ヶ月',
      value: 6,
    },
  ],
  data() {
    return {
      // date queries
      rangeTime: 1,
      fromDateCurrent: new Date(this.fromDateDefault).toISOString().substr(0, 10),
      toDate: addMonths(new Date(this.fromDateDefault).toISOString().substr(0, 10), 1),
      toDateSelected: this.toDateDefault,
      menuDate: false,
      // facility
      facilityList: [],
      selectedFacility: {
        id: null,
        name: '全て',
      },
      // room
      roomTypeList: [],
      accommodationPriceList: [],
      bookingPlanList: [],
      // inventory
      inventoryList: [],
      // mwWeek
      mwWeekCalendarList: [],
      mwWeekNo: {},
    };
  },
  async mounted() {
    await this.fetchFacilityList();
    this.resetAndGetNewData();
    const facility = this.facilityList.find(
      (facility) => facility.id === this.defaultFacilityId,
    );
    if (facility) this.selectedFacility = facility;
  },
  computed: {
    facilityListItem() {
      const copied = this.facilityList ? this.facilityList.slice() : [];
      const datas = {
        id: null,
        name: '全て',
      };
      copied.unshift(datas);
      return copied;
    },
    fromDate: {
      get() {
        return this.fromDateCurrent;
      },
      set(value) {
        this.fromDateCurrent = value;
        this.toDate = addMonths(value, this.rangeTime);
      },
    },
    idSelectedFacility: {
      get() {
        return this.selectedFacility ? this.selectedFacility.id : null;
      },
      set(id) {
        const facility = this.facilityList.find((item) => item.id === id);
        this.selectedFacility = facility;
      },
    },
    dateListAll() {
      return getAllDateInBetween2Dates(this.fromDate, this.toDate);
    },
    getMonthsList() {
      const months = [
        {
          value: formatMonth(this.fromDateCurrent),
          style: `width: calc(170px * ${
            getTotalDaysInMonth(this.fromDateCurrent) -
            getDayInMonth(this.fromDateCurrent) +
            1
          })`,
        },
      ];

      for (let i = 1; i <= this.rangeTime; i++) {
        const date = addMonths(this.fromDateCurrent, i);
        months.push({
          value: formatMonth(date),
          style: `width: calc(170px * ${
            i === this.rangeTime
              ? getDayInMonth(this.toDate)
              : getTotalDaysInMonth(date)
          })`,
        });
      }

      return months;
    },
  },
  watch: {
    async toDate() {
      await this.getInventoryList();
      this.fetchData();
    },
    async selectedFacility() {
      this.resetAndGetNewData();
    },
  },
  methods: {
    formatDate,
    getDate,
    addMonths,
    ...mapMutations(['setLoadingOverlayHide', 'setLoadingOverlayShow']),
    // ================= HANDLE DATE ========================
    nextMonth() {
      this.fromDate = addMonths(this.fromDate, this.rangeTime);
    },
    prevMonth() {
      this.fromDate = addMonths(this.fromDate, -this.rangeTime);
    },
    chooseToday() {
      this.fromDate = new Date().toISOString().substr(0, 10);
    },
    changeTime(v) {
      this.toDate = this.addMonths(this.fromDate, v);
    },
    // ================= HANDLE DATA ========================
    async resetAndGetNewData() {
      this.resetData();
      this.resetReservationRooms();
      await this.getInventoryList();
      this.fetchData();
    },
    async fetchData() {
      apolloClient
        .query({
          query: gql`${ACCOMMODATION_PRICE_LIST}`,
          variables: {
            facilityId: this.idSelectedFacility,
            accommodationFromDate: this.fromDate,
            accommodationToDate: this.toDate,
            nonProductFromDate: this.fromDate,
            nonProductToDate: this.toDate,
            mwWeekCalendarFromDate: this.fromDate,
            mwWeekCalendarToDate: this.toDate,
            bookingPlanFilter: {
              availableFromDate: this.fromDate,
              availableToDate: this.toDate,
              facilityId: this.idSelectedFacility,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const data = res.data;
          this.accommodationPriceList = data.accommodationPriceList;
          this.mwWeekCalendarList = data.mwWeekCalendarList;
          this.bookingPlanList = data.bookingPlanList.items;

          const mwWeekNo = {};
          this.mwWeekCalendarList.forEach((mwWeekCalendar) => {
            const key =
              mwWeekCalendar.weekNo +
              (mwWeekCalendar.latterHalf ? '後半' : '前半');
            const mwDate = mwWeekCalendar.mwDate;
            if (!mwWeekNo[key]) {
              mwWeekNo[key] = [];
              mwWeekNo[key] = [mwDate];
            } else if (!mwWeekNo[key].includes(mwDate)) {
              mwWeekNo[key].push(mwDate);
            }
          });

          this.mwWeekNo = mwWeekNo;
        });
    },
    setDefault() {
      this.toDate = new Date(addMonths( this.fromDateDefault, 1)) - new Date(this.toDateDefault) < 0 ?  addMonths(this.fromDateDefault, 1) : this.toDateDefault
    },
    async fetchFacilityList() {
      await apolloClient
        .query({
          query: gql`${FACLILITY_LIST}`,
        })
        .then((res) => {
          const facilityListData = res.data.facilityList;
          let roomTypeList = []
          facilityListData.forEach(facility => {
            roomTypeList = roomTypeList.concat(facility.roomTypes)
          })
          this.roomTypeList = roomTypeList
          if (!this.facilityIdList || this.facilityIdList.length === 0) {
            this.facilityList = facilityListData;
          } else {
            for (let j = 0; j < this.facilityIdList.length; j++) {
              const item = facilityListData.find(
                (item) => item.id === this.facilityIdList[j],
              );

              this.facilityList.push(item);
            }
          }
        });
    },
    resetData() {
      this.accommodationPriceList = [];
      this.mwWeekCalendarList = [];
      this.bookingPlanList = [];
    },
    // ================= HANDLE ITEM ========================
    filterBookingPlan(roomTypeId, type) {
      return this.bookingPlanList.filter(
        (item) =>
          item.roomTypeId === roomTypeId &&
          item.targetBookingType === type &&
          item.enabled,
      );
    },
    findMWItem(currentDate) {
      const item = this.mwWeekCalendarList.find(
        (item) => item.mwDate === currentDate,
      );

      return item ? item.mwRank : '';
    },
  },
};
