import gql from 'graphql-tag';
import {
  FACILITY_LIST,
  ROOMTYPELIST_TEM_CONTRACT,
} from '@/api/graphql/customer/response/detail/VMTemporaryContract/index.js';
// import router from '@/router';
export default {
  data() {
    return {
      facilityList: [],
      roomTypeList: []
    };
  },
  computed: {
    listYears() {
      const arr = [];
      const yearNow = parseInt(new Date().getFullYear());
      const yearStart = yearNow + 15;
      const yearEnd = yearNow - 10;
      for (let i = yearEnd; i <= yearStart; i++) {
        arr.push({ id: i, name: i });
      }
      return arr;
    },

    listNumYear() {
      const arr = [];
      for (let i = 1; i <= 20; i++) {
        arr.push({ id: i, name: i + '年' });
      }
      return arr;
    },

    month() {
      const arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push(i);
      }
      return arr;
    },
  },

  methods: {
    date(month) {
      const arr = [];
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          for (let i = 1; i <= 31; i++) {
            arr.push(i);
          }
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          for (let i = 1; i <= 30; i++) {
            arr.push(i);
          }
          break;
        case 2:
          for (let i = 1; i <= 28; i++) { // the 29 is never allowed
            arr.push(i);
          }
          break;
      }
      return arr;
    },
  },
  apollo: {
    facilityList: {
      query: gql`${FACILITY_LIST}`,
      update: data => data.facilityList
    },
    roomTypeList: {
      query: gql`${ROOMTYPELIST_TEM_CONTRACT}`,
      variables() { return {facilityId: this.facilityId }; },
      skip() { return !this.facilityId },
      update: data => data.roomTypeList
    }
  }
}
