<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <h4 style="color: #232222; font-size: 16px !important">
        {{ year }}年
        <v-progress-circular v-if="this.$apollo.queries.setDefaultInventory.loading" indeterminate size="18" width="3"/>
        <span v-else-if="inventorySelected">枠選択済み</span>
        <span v-else class="text--red-dark">枠選択エラー、個別枠選択して下さい</span>
      </h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div>
        <div class="d-flex justify-start">
          <div>
            <h4 class="text-10px text-header-field" style="color: #000000">
              チェックイン日
            </h4>
            <h4 class="text-14px" style="color: #444444">
              {{ this.booking.checkInDate | formatDate }}
            </h4>
          </div>
          <div class="mx-4">
            <h4 class="text-10px text-header-field" style="color: #000000">
              チェックアウト日
            </h4>
            <h4 class="text-14px" style="color: #444444">
              {{ this.booking.checkOutDate | formatDate }}
            </h4>
          </div>
          <div>
            <h4 class="text-10px text-header-field" style="color: #000000">
              宿泊日数
            </h4>
            <h4 class="text-14px" style="color: #444444">
              {{ stayLength }}泊
            </h4>
          </div>
        </div>
      </div>
      <v-btn class="t-btn--prm mt-2" @click="startInventorySelection">
        枠選択
      </v-btn>
    </v-expansion-panel-content>
    <FrameSelectionDialog
      :defaultFacilityId="roomType.facilityId"
      :fromDateDefault="booking.checkInDate"
      :toDateDefault="booking.lastStayDate"
      :facilityIdList="[roomType.facilityId]"
      :roomTypeSelect="[roomType.id]"
      :isShowFrameSelection.sync="isShowFrameSelection" 
      :extra-check="() => true"
      contractName="バケーションマスターズ"
      :canClickNext="canClickNext"
      :selectedInventories="selectedInventories"
      allowOverBooking
      mode="vm"
      @getData="getData"
    />
  </v-expansion-panel>
</template>

<script>
import FrameSelectionDialog from '@/views/customer/response/VMTemporaryContract/FrameSelectionDialog';
import gql from 'graphql-tag';

export default {
  components: {FrameSelectionDialog},
  props: {
    contractId: Number,
    roomType: Object,
    booking: Object,
  },
  data() {
    return {
      isShowFrameSelection: false,
    }
  },
  computed: {
    year () {
      return new Date(this.booking.checkInDate).getFullYear()
    },
    stayLength () {
      return this.booking.stayLength
    },
    inventorySelected () {
      return this.booking.days?.length === this.stayLength;
    },
    selectedInventories () {
      return this.booking.days?.map(d => ({
        date: d.stayDate,
        index: `${d.inventoryTypeId}-${1}`,
        inventoryTypeId: d.inventoryTypeId,
        roomTypeId: `${this.roomType.id}`,
      })) ?? []
    }
  },
  methods: {
    startInventorySelection() {
      this.isShowFrameSelection = true
    },
    getData({selectedReservationRooms}) {
      this.$emit('selected', {
        ...this.booking,
        days: selectedReservationRooms.map(item => {
          return {
            stayDate: item.date,
            inventoryTypeId: item.inventoryTypeId
          }
        })
      })
    },
    canClickNext({selected}) {
      return selected.length === this.stayLength
    },
  },
  apollo: {
    setDefaultInventory: {
      query:  gql`query (
          $contractId: Int!
          $roomTypeId: Int!
          $checkInDate: DateString!
          $checkOutDate: DateString!
        ) {
          getAutoSelectInventories(
            contractId: $contractId, roomTypeId: $roomTypeId, checkInDate: $checkInDate, checkOutDate: $checkOutDate
          ) {
            days {
              stayDate
              inventoryTypeId
            }
          }
        }`,
      variables() {
        return {
          contractId: this.contractId,
          roomTypeId: this.roomType.id,
          checkInDate: this.booking.checkInDate,
          checkOutDate: this.booking.checkOutDate
        }
      },
      skip() {
        return this.booking.days?.length > 0
      },
      manual: true,
      result (result) {
        if (result.data) {
          const days = result.data.getAutoSelectInventories.days;
          this.$set(this.booking, 'days', days.map(day => {
            return {
              stayDate: day.stayDate,
              inventoryTypeId: day.inventoryTypeId
            }
          }));
        }
      },
    }
  }
}
</script>