<template>
  <v-card>
    <v-card-text>
      <div class="d-flex">
        <div class="prices col">
          <div v-for="date in monthDatesWithoutLeapYear" :key="date">
            <v-text-field 
              type="number" 
              :value="priceByMonthDate[date]" 
              :label="getLabel(date)"
              @input="v => emitInput(date, v)"
              hide-details
              :rules="[$rules.required]"
              prefix="¥"
            >
            </v-text-field>
          </div>
          <div class="d-flex justify-space-between">
            <div class="price-total">
              <label>合計金額</label>
              <div>{{ totalWithoutLeapYear | currency2 }}</div>
            </div>
            <div class="ml-2">
              <v-text-field 
                type="number" 
                label="TVP支払い分"
                :value="tvpPaymentAmount" 
                :rules="[$rules.required]"
                @input="emitTvpPaymentAmount"
                prefix="TVP"
                suffix="Pts"
              >
              </v-text-field>
            </div>
          </div>
          <div v-if="includeLeapYear" class="mt-6">
            <v-text-field 
              type="number" 
              :value="priceByMonthDate['02-29']" 
              :label="getLabel('02-29')"
              :rules="[$rules.required]"
              @input="v => emitInput('02-29', v)"
              hide-details
              prefix="¥"
            >
            </v-text-field>
          </div>
        </div>
        <div class="price-bulk col">
          <v-textarea
            outlined
            label="料金(税込)貼り付け"
            v-model="bulkPrice"
            :rows="monthDates.length"
            dense
          />
          <v-btn outlined color="primary" @click="bulkCopy">反映</v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {getDaysArray, getMonthDateString} from '@/utils/dateUtil'
export default {
  model: {
    prop: 'priceByMonthDate',
  },
  props: {
    bookings: Array,
    priceByMonthDate: Object,
    tvpPaymentAmount: Number,
  },
  data () {
    return {
      bulkPrice: null
    }
  },
  computed: {
    monthDates() {
      const monthDates = [];
      this.bookings.forEach(booking => {
        const days = getDaysArray(new Date(booking.checkInDate), new Date(booking.lastStayDate));
        days.forEach(date => {
          const monthDateStr = getMonthDateString(date)
          if (monthDates.indexOf(monthDateStr) === -1) {
            monthDates.push(monthDateStr);
          }
        })
      })
      return monthDates
    },
    monthDatesWithoutLeapYear() {
      return this.monthDates.filter(date => date !== '02-29')
    },
    includeLeapYear() {
      return this.monthDates.length !== this.monthDatesWithoutLeapYear.length
    },
    totalWithoutLeapYear() {
      return this.monthDatesWithoutLeapYear.reduce((sum, date) => sum + Number(this.priceByMonthDate[date] ?? '0'), 0)
    }
  },
  watch: {
    monthDates: {
      immediate: true,
      handler(v) {
        const result = {};
        v.forEach(date => {
          result[date] = this.priceByMonthDate[date] ?? null
        })
        this.$emit('input', result);
      }
    }
  },
  methods: {
    getLabel(monthdate) {
      const [month, date] = monthdate.split('-')
      return `${Number(month)}月${Number(date)}日料金(税込)`
    },
    emitInput(date, price) {
      this.emitBulk([{date, price}])
    },
    emitBulk(dateAndPrices) {
      const result = { ...this.priceByMonthDate };
      dateAndPrices.forEach(({date, price}) => {
        result[date] = price ? Number(price) : null
      });
      this.$emit('input', result)
    },
    bulkCopy() {
      const lines = this.bulkPrice.split(/\r?\n/);
      const toEmit = []
      lines.forEach((line, index) => {
        line = line.replace(/[^0-9]/g, '')
        if (!line) return;

        const date = this.monthDatesWithoutLeapYear[index];
        if (date) {
          toEmit.push({date, price: line})
        }
      })

      this.emitBulk(toEmit)
    },
    emitTvpPaymentAmount(value) {
      value = value.replace(/[^0-9]/g, '')
      if (value) value = Number(value);
      else value = null;

      this.$emit('update:tvpPaymentAmount', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.col {
  width: 50%;
}
.price-total {
  min-width: 80px;
}
</style>