<template>
  <div>
    <h4>予約状況リスト(選択範囲の前後一週間+三年)</h4>
    <v-data-table class="elevation-1 mt-1" :loading="$apollo.queries.stats.loading">
      <template v-slot:body="{}">
        <thead class="v-data-table-header">
          <tr>
            <th class="year-col">年</th>
            <th
              v-for="(date, idx) in monthDates"
              :key="idx"
              class="text-uppercase"
              scope="col"
              :id="theAnchorMonthDate === date.monthDate ? 'theAnchorMonthDate' : ''"
            >
              <tr>
                <td colspan="4">{{ formatDate(date.monthDate) }}</td>
              </tr>
              <tr class="trtwo">
                <td>部屋数</td>
                <td>空室数</td>
                <td>固定数</td>
                <td>固定%</td>
              </tr>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="year in yearArray" :key="year">
            <th class="year-col">{{ year }}</th>
            <th
              v-for="(date, dateIndex) in monthDates"
              :key="dateIndex"
              class="text-uppercase"
              scope="col"
            >
              <data-item :item="getDataItem(year, date.monthDate, date.yearOffset)" />
            </th>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { VMOCCUPANCYLIST_TEM_CONTRACT } from '@/api/graphql/customer/response/detail/VMTemporaryContract/index.js';
import { getMonthDateString, getDaysArray } from '@/utils/dateUtil';

import gql from 'graphql-tag';
import dataItem from './data-item.vue';
export default {
  components: { dataItem },
  props: {
    roomTypeId: Number,
    fromYear: Number,
    years: Number,
    fromMonth: Number,
    fromDate: Number,
    toMonth: Number,
    toDate: Number,
  },
  data () {
    return {
      stats: {}
    }
  },
  computed: {
    theAnchorMonthDate () {
      return getMonthDateString(new Date(this.fromYear, this.fromMonth - 1, this.fromDate + 4))
    },
    yearArray () {
      return [...Array(this.showYears)].map((_, i) => this.fromYear + i)
    },
    isOveryear() {
      return this.fromMonth > this.toMonth || (
        this.fromMonth === this.toMonth && this.fromDate > this.toDate
      )
    },
    showYears() {return this.years + 3},
    fromDateOfFirstYear() { return new Date(this.fromYear, this.fromMonth - 1, this.fromDate) },
    showFromDate() {
      const date = new Date(this.fromDateOfFirstYear);
      date.setDate(date.getDate() - 7);
      return date;
    },
    toDateOfFirstYear() {
      let year = this.fromYear;
      if (this.isOveryear) {
        // this means that the toDate is next year, so add 1 year
        year += 1
      }
      const date = new Date(year, this.toMonth - 1, this.toDate)
      return date
    },
    showToDate() {
      const date = new Date(this.toDateOfFirstYear);
      date.setDate(date.getDate() + 7);
      return date;
    },
    isValidInput() {
      return this.roomTypeId && this.fromYear && this.years
        && this.fromMonth && this.fromDate && this.toMonth && this.toDate;
    },
    monthDates() {
      // calculate the date range for all the dates, and pick the longest (leap year)
      let dates = [];
      for (let i = 0; i < this.showYears; i++) {
        const from = new Date(this.showFromDate)
        from.setFullYear(from.getFullYear() + i)
        const to = new Date(this.showToDate)
        to.setFullYear(to.getFullYear() + i)
        const dateRange = getDaysArray(from, to)
        if (dates.length < dateRange.length) {
          dates = dateRange
        }
      }
      return dates.map(date => {
        const monthDate = getMonthDateString(date)
        // get the year offset
        const yearOffset = new Date(date).getFullYear() - new Date(dates[0]).getFullYear()
        return {
          yearOffset,
          monthDate
        }
      })
    }
  },
  methods: {
    formatDate (monthdate) {
      const [month, date] = monthdate.split('-')
      return `${Number(month)}月${Number(date)}日`
    },
    getDataItem(year, date, yearOffset) {
      const dateString = `${year + yearOffset}-${date}`;
      const days = this.stats.years?.find(row => row.year === year)?.days
      return days?.find(day => day.stayDate === dateString)
    }
  },
  mounted() {
    const element = document.getElementById('theAnchorMonthDate')
    element.scrollIntoView()
  },
  apollo: {
    stats: {
      query: gql`${VMOCCUPANCYLIST_TEM_CONTRACT}`,
      variables() {
        return {
          fromYear: this.fromYear,
          years: this.showYears,
          roomTypeId: this.roomTypeId,
          fromMonth: this.showFromDate.getMonth() + 1,
          fromDate: this.showFromDate.getDate(),
          toMonth: this.showToDate.getMonth() + 1,
          toDate: this.showToDate.getDate(),
        }
      },
      skip () {
        return !this.isValidInput
      },
      update: data => data.vmOccupancyList
    }
  }
}
</script>

<style lang="scss" scoped>
.year-col {
  position: sticky;
  left: 0;
  background-color: white;
}
</style>