<template>
  <div class="t-calendar-item">
    <div class="t-calendar-item__title">
      {{ getItemName }}
    </div>
    <div class="t-calendar-item__data-list">
      <Pass
        v-for="date in dateListAll"
        :key="date"
        :metadata="{
          price: getPrice(date),
        }"
      >
        <div
          class="t-calendar-item__data-item"
          slot-scope="{ metadata }"
          :class="getClassObject()"
        >
          {{ findAccomItem(metadata.price) }}
        </div>
      </Pass>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/constants/functions'
import Pass from '@/components/shared/Pass.vue'

export default {
  name: 'AccomItem',
  components: { Pass },
  props: {
    type: String,
    roomId: [Number, String],
    order: [Number, String],
    dateListAll: {
      type: Array,
      default: () => []
    },
    accommodationPriceList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getItemName () {
      switch (this.type) {
        case 'vs':
          return `VS${this.order}人`
        case 'vm':
          return `MO${this.order}人`
        case 'sp':
          return 'SP'
        case 'fp':
          return 'FP'
        case 'dp':
          return 'DP'
        default:
          return ''
      }
    },
    getKeyValue () {
      const order = this.order
      const type = this.type

      if (order) {
        return `${type}Price${order}`
      } else if (type === 'sp') {
        return 'spPoint'
      } else if (type === 'fp') {
        return 'fpPoint'
      } else if (type === 'dp') {
        return 'dpPrice'
      }

      return ''
    }
  },
  methods: {
    getClassObject () {
      const type = this.type
      return {
        'bg--vs': type === 'vs',
        'bg--vm': type === 'vm',
        'bg--sp': type === 'sp',
        'bg--dp': type === 'dp',
        'bg--fp': type === 'fp',
      }
    },
    getPrice (stayDate) {
      const roomId = this.roomId
      const item = this.accommodationPriceList.find(
        (el) => el.stayDate === stayDate && el.roomTypeId === roomId
      )

      if (!item) return

      return item[this.getKeyValue]
    },
    findAccomItem (price) {
      const type = this.type
      if (!price) return

      if (this.order || type === 'dp') {
        return `¥${formatCurrency(price)}`
      } else if (type === 'sp' || type === 'fp') {
        return `${formatCurrency(price)}pt`
      }
    },
  }
}
</script>

<style lang="scss" scoped></style>
