// components
import Pass from '@/components/shared/Pass.vue';
import {
  INVENTORY_LIST,
} from '@/api/graphql/inventory/inventory';
// utils
import {
  getDiff2Days,
} from '@/utils/dateUtil';
// lodash
import { sortBy, chain } from 'lodash';
import gql from 'graphql-tag';
import { isWarningInventoryType } from '@/utils/inventory'

export const bookingMixin = {
  components: {
    Pass,
  },
  props: {
    selectedInventories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      inventoryTypeList: [],
      // cells
      selectedReservationRooms: [],
    };
  },
  computed: {
    getSelectedReservationRoomDates() {
      return sortBy(this.selectedReservationRooms.map((ele) => ele.date));
    },
    getSelectedReservationRoomId() {
      return this.selectedReservationRooms.length > 0
        ? this.selectedReservationRooms[0].roomTypeId
        : null;
    },
  },
  watch: {
    selectedInventories: {
      immediate: true,
      handler() {
        this.selectedReservationRooms = this.selectedInventories.slice()
      }
    },
    selectedReservationRooms: {
      handler(v) {
        this.$emit('update:selectedInventories', this.selectedReservationRooms.slice())
      }
    }
  },
  mounted() {
    this.getInventoryTypeList()
    this.getItemSelect()
  },
  methods: {
    // ================= HANDLE DATA ========================

    getItemSelect() {
      if (this.selectedInventories?.length) {
        // in this case this.selectedReservationRooms will be set thru selectedInventories so do nothing
      } else if(this.getSelectItem) {
        this.selectedReservationRooms = this.getSelectItem
      } else {
        this.selectedReservationRooms = []
      }
    },

    async getInventoryList() {
      this.setLoadingOverlayShow();

      const facilityId = this.selectedFacility.id;
      await this.$apollo
        .query({
          query: gql`${INVENTORY_LIST}`,
          variables: {
            dateFrom: this.fromDate,
            dateTo: this.toDate,
            facilityId: facilityId,
            roomTypes: this.roomTypeSelect
            // inventoryTypes: [4]
          },
          // fetchPolicy: 'no-cache',
        })
        .then((res) => {
          this.inventoryList = []
          const list = res.data.inventoryList;

          const result = chain(list)
            .groupBy('roomTypeId')
            .map((value, key) => ({ roomTypeId: key, calendar: value }))
            .value();

          result.forEach((el) => {
            el.calendar = chain(el.calendar)
              .groupBy('stayDate')
              .map((value, key) => ({ date: key, item: value }))
              .value();
          });
            for (let j = 0; j < result.length; j++) {
              if (this.roomTypeSelect.find(item => item === parseInt(result[j].roomTypeId))) {
                this.inventoryList.push(result[j])
              }
          }
          // this.inventoryList = result;

        })
        .finally(() => this.setLoadingOverlayHide());
    },
    getMwRank(date) {
      const mwWeek = this.mwWeekCalendarList.find(
        (mwWeek) => mwWeek.mwDate === date,
      );

      return mwWeek ? mwWeek.mwRank : '';
    },
    // ================= HANDLE BOOKING DATA ========================
    /**
     *
     * @param {*} roomTypeId
     * @param {*} date
     * @param {*} index
     * @param {*} isSelected
     */
     selectReservationRoom(
       roomTypeId, 
       inventoryTypeId,
       roomTypeName,
       date,
       index,
       isSelected,
       canSelect = null
      ) {
        const extraCheck = canSelect ?? (() => this.selectedReservationRooms.length === 0);
      if (!isSelected && extraCheck({
        selected: this.selectedReservationRooms
      })) {
        this.selectedReservationRooms.push({
          roomTypeId: roomTypeId,
          inventoryTypeId: inventoryTypeId,
          roomTypeName: roomTypeName,
          date: date,
          index: index,
        });
      } else {
        this.selectedReservationRooms = this.selectedReservationRooms.filter(
          (ele) =>
            !(
              ele.roomTypeId === roomTypeId &&
              ele.date === date &&
              ele.index === index
            ),
        );
      }
    },
    isSeletedCell(roomTypeId, date, index) {
      return !!this.selectedReservationRooms.find(
        (ele) =>
          ele.roomTypeId === roomTypeId &&
          ele.date === date &&
          ele.index === index,
      );
    },
    isValidCell(roomTypeId, date, index) {
      const selectedRoomTypeId = this.getSelectedReservationRoomId;
      if (!selectedRoomTypeId) return true;
      else if (roomTypeId !== selectedRoomTypeId) return false;

      const dates = this.getSelectedReservationRoomDates;
      const minDate = dates[0];
      const maxDate = dates[dates.length - 1];
      const existDate = this.selectedReservationRooms.find(
        (ele) => ele.date === date,
      );

      if (existDate) {
        if (existDate.index !== index) return false;
        else if (date === minDate || date === maxDate) return true;
        return false;
      }

      if (
        (Math.abs(getDiff2Days(minDate, date)) === 1 ||
        Math.abs(getDiff2Days(date, maxDate)) === 1) && this.$route.path !== '/corporate-ticket/create-booking'
      ) {
        return true;
      } else if (
        (Math.abs(getDiff2Days(minDate, date)) === 0 ||
        Math.abs(getDiff2Days(date, maxDate)) === 0) && this.$route.path === '/corporate-ticket/create-booking'
      ) {
        return true;
      }
      return false;
    },
    resetReservationRooms() {
      this.selectedReservationRooms = [];
    },
    /**
     *
     * @param {*} bookings
     * @param {*} roomName
     * @returns
     */
    checkDuplicateBooking(bookings, roomName) {
      let count = 0;
      for (let i = 0; i < bookings.length; i++) {
        if (roomName === bookings[i].room.name) {
          count = count + 1;
          if (count > 1) return true;
        }
      }

      return false;
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getBgRoom(id) {
      const listRoomType = this.roomTypeList.filter(item => typeof item !== 'undefined')
      const roomType = listRoomType.find(
        (roomType) => roomType.id === parseInt(id),
      );
      if (roomType) return roomType.backgroundColor;
      return '';
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getNameRoomType(id) {
      const listRoomType = this.roomTypeList.filter(item => typeof item !== 'undefined')
      const roomType = listRoomType.find(
        (roomType) => roomType.id === parseInt(id),
      );

      if (roomType) return roomType.name;
      return '';
    },

    async getInventoryTypeList () {
      await this.$apollo
        .query({
          query: gql`query {
            inventoryTypeList {
              id
              name
              nameAbbr
              externallyReserved
              color
            }
          }`
        })
        .then((data) => {
          for (let i = 0; i < data.data.inventoryTypeList.length; i++) {
            const item = {
              id: data.data.inventoryTypeList[i].id,
              color: data.data.inventoryTypeList[i].color,
              nameAbbr: data.data.inventoryTypeList[i].nameAbbr
            }
            this.inventoryTypeList.push(item)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    getColorInventory (inventoryTypeId) {
      for (let i = 0; i < this.inventoryTypeList.length; i++) {
        if (this.inventoryTypeList[i].id === inventoryTypeId ?? this.inventoryTypeId) {
          return this.inventoryTypeList[i].color
        }
      }
    },

    checkTextColor (id) {
      const idTextBlack = [1, 4, 3, 5, 6]
      if (!idTextBlack.includes(id)) return 'color: #fff'
      else return 'color: #000'
    },

    checkDuplicate (reservation, roomName) {
      var count = 0
      for (let i = 0; i < reservation.bookings.length; i++) {
        if (roomName === reservation.bookings[i].room.name) {
          count = count + 1
        }
      }
      return count
    },

    getInventoryWarning (days) {
      const messages = days.map(day => {
        if (isWarningInventoryType(day.inventoryTypeId)) {
          return this.inventoryTypeList.find(iv => iv.id === day.inventoryTypeId)?.nameAbbr
        }
        return null
      }).filter(msg => !!msg)

      if (messages.length) {
        const message = [...new Set(messages)].join('・')
        return message + '枠が指定されています。予約を続行しますか？'
      } else {
        return ''
      }
    }
  },
};
