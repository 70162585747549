<template>
  <v-dialog v-model="isShow" @click:outside="closeDialog">
    <div class="frame-selection" style="width: fit-content;">
      <!-- header -->
      <header style="width: calc(100vw - 72px)">
        <p class="header-title">枠を選択して下さい</p>
        <div
          class="selected-reservations"
        >
          <!-- choosing -->
          <div class="modal-custom_block d-inline-flex align-center mb-4">
            <template
              v-if="selectedReservationRooms && selectedReservationRooms.length > 0">
            <h3 class="color-red mr-4">
              {{ $t('reservationCalendar.select') }}
            </h3>
            <div class="mr-4">
              <p class="modal-custom_txt mb-2">
                {{ $t('reservationCalendar.checkIn') }}
              </p>
              <p class="mb-0">
                {{
                  `${formatDate(getSelectedReservationRoomDates[0])}から${
                    selectedReservationRooms.length
                  }泊`
                }}
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.facility') }}
              </p>
              <p class="mb-0">{{ selectedFacility.name }}</p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.typeRoom') }}
              </p>
              <p class="mb-0">{{ selectedReservationRooms[0].roomTypeName }}</p>
            </div>
            </template>
            <div v-else>
              枠を選択して下さい
            </div>
          </div>
          <!-- choosing -->
          <!-- header-actions -->
          <div class="header-actions">
            <div>
              <p class="font-weight-bold">利用契約</p>
              <p>{{contractName}}</p>
            </div>
            <v-btn
              color="error"
              class="btn-close mr-2"
              @click="
                closeDialog();
              "
              ><v-icon>mdi-close</v-icon>
              {{ $t('buttons.close') }}
            </v-btn>
            <v-btn color="primary" @click.prevent="next" :disabled="!canGoNext">次へ</v-btn>
          </div>
          <!-- header-actions -->
        </div>
        <div class="flex-header px-4 mt-5">
          <v-btn @click="prevMonth" icon>
            <v-icon size="35">mdi-chevron-double-left</v-icon>
          </v-btn>
          <v-btn @click="nextMonth" icon :disabled="(new Date(toDate) - new Date(toDateDefault)) === 0">
            <v-icon size="35">mdi-chevron-double-right</v-icon>
          </v-btn>
          <v-btn
            @click="chooseToday"
            outlined
            small
            class="mr-5 ml-1"
            color="var(--text__gray)"
          >
            {{ $t('common.toDay') }}
          </v-btn>
          <!-- datetime -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon color="#000000">mdi-calendar-month</v-icon>
                <v-btn
                  v-model="fromDate"
                  v-on="on"
                  small
                  class="btn--date mr-5"
                >
                  {{ formatDate(fromDate) }}
                </v-btn>
              </div>
            </template>
            <v-date-picker
              v-model="fromDate"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              @change="fetchData"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <label class="text--small mr-1">期間</label>
          <div style="width: 80px !important">
            <v-select
            :items="$options.timeRange"
            item-text="text"
            item-value="value"
            v-model="rangeTime"
            @change="changeTime"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
          </div>
          <!-- datetime -->
          <!-- facility -->
          <label class="ml-5 text--small mr-1">{{
            $t('common.facility')
          }}</label>
          <div style="width: 250px !important">
            <v-select
            :items="facilityListItem"
            v-model="idSelectedFacility"
            item-text="name"
            item-value="id"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom"
          ></v-select>
          </div>
          <!-- facility -->
        </div>
      </header>
      <!-- header -->
      <!-- body -->
      <div
        class="t-wrapper"
        v-if="
          accommodationPriceList.length > 0 ||
          mwWeekCalendarList.length > 0 ||
          bookingPlanList.length > 0
        "
      >
        <div class="t-calendar">
        </div>
      </div>
      <!-- main -->
      <div class="modal-custom_block d-inline-flex align-center mb-4">
        <div class="main_calendar white mx-0 my-0">
          <!-- title -->
          <div class="d-flex">
            <div class="main-calendar__name">
              <div class="main_calendar_title">{{selectedFacility.name}}</div>
            </div>
            <div class="main_calendar__header-list">
              <div
                v-for="(month, index) in getMonthsList"
                :key="index"
                :style="month.style"
              >
                {{ month.value }}
              </div>
            </div>
          </div>
          <!-- title -->
          <!-- room-type -->
          <Pass
            v-for="(item, index) in inventoryList"
            :key="item.roomTypeId"
            :metadataInventory="{
              roomTypeName: getNameRoomType(item.roomTypeId),
            }"
          >
            <div
              slot-scope="{ metadataInventory }"
              :class="`calendar-custom ${index >= 1 ? 'mt-5' : ''}`"
            >
              <div :style="'background-color: #' + getBgRoom(item.roomTypeId)">
                <p style="margin-left: 10px"></p>
              </div>
              <!-- room-name -->
              <div class="calendar-custom_block">
                <div class="calendar-custom_item">
                  {{getRoomTypeName(item.roomTypeId)}}
                </div>
              </div>
              <!-- room-name -->
              <!-- calendar -->
              <div
                class="calendar-custom_block canlendar-date"
                v-for="(infoCalendar, index) in item.calendar"
                :key="index"
              >
                <div
                  class="calendar-custom_item"
                  v-text="getDate(infoCalendar.date)"
                  :class="{
                    'color-red':
                      getDate(infoCalendar.date).substr(3, 4) == '(日)',
                  }"
                ></div>
                <!-- reservations -->
                <div
                  v-for="(reservation, reservationIndex) in infoCalendar.item"
                  :key="reservationIndex"
                >
                  <Pass 
                    :showOverBookFrame="allowOverBooking && reservation.rooms >= 0 && reservation.rooms <= reservation.bookings.length"
                    :lastRoomIndex="Math.max(reservation.rooms - reservation.bookings.length, (allowOverBooking && reservation.rooms >= 0 && reservation.rooms <= reservation.bookings.length ? 1 : 0))">
                    <template v-slot="{lastRoomIndex, showOverBookFrame}">
                      <div
                        class="
                          calendar-custom_item
                          h-auto
                          py-0
                          my-0
                          custom-div-farth
                        "
                      >
                        <template v-if="reservation.bookings.length > 0">
                          <div
                            class="calendar-custom_item"
                            v-for="booking in reservation.bookings"
                            :key="booking.id"
                          >
                            <div v-if="
                              checkDuplicate(reservation, booking.room.name) == 1 && !booking.blockRoom
                            "
                            :style="`background-color: #${getColorInventory(reservation.inventoryTypeId)}`"
                            class="calendar-custom_item"
                            >
                              <span :style="checkTextColor(reservation.inventoryTypeId)">{{ booking.room.name }}</span>
                            </div>
                            <div v-else-if="
                              checkDuplicate(reservation, booking.room.name) == 1 && booking.blockRoom
                              "
                              class="calendar-custom_item"
                              style="background-color: #000"
                              >
                              <span style="color: #fff">{{ booking.room.name }}</span>
                            </div>
                          <div
                              v-else-if="checkDuplicate(reservation, booking.room.name) > 1"
                              style="background-color: red"
                              class="calendar-custom_item"
                            >
                              <span style="color: #fff">{{ booking.room.name }}</span>
                            </div>
                          </div>
                        </template>
                        <Pass
                          v-for="roomIndex in lastRoomIndex"
                          :key="roomIndex"
                          :isOverBookingCell="showOverBookFrame && roomIndex === lastRoomIndex"
                          :metadata="{
                            index: `${reservation.inventoryTypeId}-${roomIndex}`,
                            isSelected: isSeletedCell(
                              item.roomTypeId,
                              infoCalendar.date,
                              `${reservation.inventoryTypeId}-${roomIndex}`,
                            ),
                            isValid: isValidCell(
                              item.roomTypeId,
                              infoCalendar.date,
                              `${reservation.inventoryTypeId}-${roomIndex}`,
                            ),
                          }"
                        >
                          <div
                            slot-scope="{ metadata, isOverBookingCell }"
                            :class="[
                              'calendar-custom_item',
                              {
                                'calendar-custom_selected': metadata.isSelected,
                                'cursor-pointer': metadata.isValid,
                              },
                            ]"
                            :style="{
                              'background-color':
                                (metadata.isValid || metadata.isSelected)
                                  ? `#${getColorInventory(reservation.inventoryTypeId)}`
                                  : '#EEEEEE',
                            }"
                            @click="
                              metadata.isValid
                                ? dateClicked(
                                    item.roomTypeId,
                                    reservation.inventoryTypeId,
                                    metadataInventory.roomTypeName,
                                    infoCalendar.date,
                                    metadata.index,
                                    metadata.isSelected,
                                  )
                                : null
                            "
                          >
                            <span class="text-small">{{isOverBookingCell ? '(選択可)オーバーブッキング用' : ''}}</span>
                          </div>
                        </Pass>
                      </div>
                    </template>
                  </Pass>
                </div>
                <!-- reservations -->
              </div>
              <!-- calendar -->
            </div>
          </Pass>
          <!-- room-type -->
        </div>
      </div>
      <!-- main -->
      <!-- body -->
    </div>
  </v-dialog>
</template>

<script>
import { mixin } from './index.mixin';
import { isVmForbiddenInventoryType, isForbiddenInventoryType, forbiddenInventoryTypeAlert } from '@/utils/inventory'

export default {
  name: 'FrameSelectionDialog',
  mixins: [mixin],
  props: {
    fromDateDefault: String,
    toDateDefault: String,
    facilityIdList: Array,
    roomTypeSelect: Array,
    defaultFacilityId: {
      type: Number,
    },
    isShowFrameSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraCheck: {
      type: Function,
      required: false
    },
    contractName: { String },
    canClickNext: {
      type: Function,
      default: () => false
    },
    allowOverBooking: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      deafult: 'all'
    }
  },
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    canGoNext() {
      return this.canClickNext({
        selected: this.selectedReservationRooms
      });
    }
  },
  watch: {
    isShowFrameSelection: {
      immediate: true,
      handler(value) {
        this.isShow = value;
      }
    },
    async facilityIdList() { 
      await this.fetchFacilityList();
      await this.restoreDefault();
    },
    fromDateDefault() { this.restoreDefault() },
    toDateDefault() { this.restoreDefault() },
    roomTypeSelect() { this.restoreDefault() },
  },
  methods: {
    restoreDefault () {
      if (this.fromDateDefault) {
        this.fromDate = this.fromDateDefault;
      }
      if (this.toDateDefault) {
        this.toDate = this.toDateDefault
      }
      this.getInventoryList();
    },
    closeDialog() {
      this.$emit('close');
      this.$emit('update:isShowFrameSelection', false);
    },
    next() {
      const ok = () => {
        this.saveSelectItem({itemSelect: this.selectedReservationRooms})
        this.closeDialog();
        this.$emit('getData', {
          facility: this.selectedFacility,
          selectedReservationRooms: this.selectedReservationRooms,
        });
      }
      const msg = this.getInventoryWarning(this.selectedReservationRooms);
      if (msg) {
        this.$confirm({
          title: '枠属性確認',
          message: msg,
          ok
        })
      } else {
        ok()
      }
    },
    dateClicked(
      roomTypeId,
      inventoryTypeId,
      roomTypeName,
      date,
      index,
      isSelected,
    ) {
      if (this.mode === 'vm') {
        if (isVmForbiddenInventoryType(inventoryTypeId)) {
          this.$alert(forbiddenInventoryTypeAlert('VM'))
          return
        }
      } else if (this.mode === 'all') {
        if (isForbiddenInventoryType(inventoryTypeId)) {
          this.$alert(forbiddenInventoryTypeAlert())
          return
        }
      }
      this.selectReservationRoom(
        roomTypeId,
        inventoryTypeId,
        roomTypeName,
        date,
        index,
        isSelected,
        this.extraCheck
      )
    }
  },
};
</script>

<style lang="scss">
@import './calendar.scss';
</style>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0 !important;
}

::v-deep {
  .calendar-custom_block {
    max-width: 168px !important;
  }
  .canlendar-date {
    background-color: #eee;
    & > div {
      background-color: white;
    }
  }
}

div.frame-selection {
  background-color: white;
  padding: 8px 0;

  header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    padding: 8px;

    .header-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .header-actions {
      box-shadow: 0px 2px 3px #00000029;
      padding: 10px;
      border-radius: 3px;
      background-color: #fafafa;
      display: flex;
      text-align: center;
      margin-bottom: 16px;
      & > div {
        width: 160px;
        margin-right: 44px;
        text-align: left;
      }
    }
  }

  .calendar-custom_block {
    width: 160px;
  }

  .mw-weeks {
    width: fit-content;
    height: 25.99px;
    display: flex;
    position: relative;
    left: 161px;
    top: 77.87px;
    line-height: 25.99px;
    text-align: center;
    z-index: 1;
    span {
      width: 152px;
      background-color: white;
      border: 1px solid #dee2e6;
      border-left: 0;
    }
  }

  div.selected-reservations {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 4px;
  }

  .calendar-custom_item {
    padding: 0 !important
  }

  .calendar-custom_item:last-child {
    border-bottom: 1px solid #dee2e6;
    padding: 0 !important;
  }

  .calendar-custom_item_txt {
    margin: 0 10px;
    font-weight: bold;
  }

  .custom-div-farth {
    border-bottom: none !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .calendar-custom_selected {
    box-shadow: 0px 1px 2px #00000029;
    border: 2px solid #ff0000 !important;
  }
  .main_calendar_title  {
    width: 177px !important;
  }
  
}
</style>

