<template>
  <div>
    <v-dialog v-model="visible" :width="width" :content-class="className" @click:outside="resetForm">
      <v-form ref="form">
      <h2 class="page-title-list">{{ title }}</h2>
      <div class="mt-3">
        <v-row>
            <v-col cols="10">
                <v-col cols="2" class="float-left">
                <span class="select-label">開始年</span>
                <v-select
                  dense
                  hide-details
                  :items="ArrayYears"
                  item-value="id"
                  item-text="name"
                  v-model="yearStart"
                  @change="getYear($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="2" class="float-left">
                <span class="select-label">契約年数</span>
                <v-select
                  dense
                  hide-details
                  :items="ArrayDuplicate"
                  item-value="id"
                  item-text="name"
                  v-model="duplicateStart"
                  @change="getDuplicateYear($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="4" class="float-left">
                <span class="select-label">施設を選択</span>
                <v-select
                  dense
                  hide-details
                  :items="facilityListNotificationRegistration"
                  item-value="id"
                  item-text="name"
                  v-model="getIdFacilityNReg"
                  @change="getFacility(getIdFacilityNReg), checkIdFacilityNReg = true, checkIdRoomTypeNReg = false"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="4" class="float-left">
                <span class="select-label">部屋タイプ</span>
                <v-select
                  dense
                  hide-details
                  :items="roomTypeListNReg"
                  item-value="id"
                  item-text="name"
                  v-model="getIdRoomTypeNReg"
                  @change="getRoomType(getIdRoomTypeNReg), checkIdRoomTypeNReg = true"
                  :rules="rules.required"
                ></v-select>
              </v-col>
            </v-col>
        </v-row>
        <v-row>
           <v-col cols="6">
                <v-col cols="3" class="float-left">
                <span class="select-label">宿泊開始月</span>
                <v-select
                  dense
                  hide-details
                  :items="months"
                  item-value="id"
                  item-text="name"
                  v-model="monthsStart"
                  @change="getDaysByMonthStart($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left">
                <span class="select-label">宿泊開始日</span>
                <v-select
                  dense
                  hide-details
                  :items="daysStartArr"
                  item-value="id"
                  item-text="name"
                  v-model="daysStart"
                  @change="getdaysStartSelected($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left">
                <span class="select-label">宿泊最終月</span>
                <v-select
                  dense
                  hide-details
                  :items="months"
                  item-value="id"
                  item-text="name"
                  v-model="monthsEnd"
                  @change="getDaysByMonthEnd($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left">
                <span class="select-label">宿泊最終日</span>
                <v-select
                  dense
                  hide-details
                  :items="daysEndArr"
                  item-value="id"
                  item-text="name"
                  v-model="daysEnd"
                  @change="getdaysEndSelected($event)"
                  :rules="rules.required"
                ></v-select>
              </v-col>
           </v-col>
        </v-row>
      </div>
      <slot name="content" class="container-cus">
           <v-card class="mt-2" outlined max-width="95%" style="width : 95%; background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000005D;
border-radius: 3px;
opacity: 1;">
              <div class="list-all-container my-2">
                <div style="height: 20px;">
                </div>
                <v-row>
                  <v-col :cols="6" class="float-left" >
                    <v-row v-for="(item,idx) in timeArray" :key="idx">
                      <v-col class="float-left">
                        <v-text-field
                          dense
                          hide-details
                          :label= item.value
                          :id = item.idx
                          :rules="rules.numberCurrency"
                          v-model="itemsPrice[idx]"
                          @input="getPriceByDay($event,idx)"
                          class="input-cus"
                        >
                        </v-text-field> 
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <h5>料金(税込)貼り付け</h5>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      v-model="data"
                    ></v-textarea>
                    <v-btn class="btn-billing btn-crm-primary mr-4 text-14px mt-3" @click="showPrice =true; getArrPrice()">
                        反映
                      </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="float-left" >
                    <v-row>
                      <v-col class="float-left">
                        <label>合計金額(税込)</label><br/>
                        <label class="ml-5">{{changedisplayValue(allPrice)}}</label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="float-left" >
                    <v-row>
                      <v-col class="float-left mb-5">
                        <v-text-field
                          v-if="isTN"
                          dense
                          hide-details
                          label="閏年2月29日料金(税込)"
                          v-model="itemsPrice[timeArray.length]"
                          :rules="rules.numberCurrency"
                          @input="getPriceByDay($event,timeArray.length)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              
            </v-card>
            <div class="mt-2" max-width="95%" style="width : 95%"  v-if="isShow()">  
                <v-expansion-panels
                    multiple
                >
                    <v-expansion-panel
                    v-for="(item,i) in ListPanel"
                    :key="i"
                    style="background: #FFFFFF 0% 0% no-repeat padding-box;
                      box-shadow: 0px 3px 6px #0000005D;
                      border-radius: 3px;
                      opacity: 1;"
                    >
                    <v-expansion-panel-header v-if="getVMConvertIntoConfirmedContract" class="year-panel">{{item}}年 <label class="status-err ml-2" v-if="checkIdFacilityNReg && checkIdRoomTypeNReg">   枠選択エラー、個別枠選択してください</label> </v-expansion-panel-header>
                    <v-expansion-panel-header v-else  class="year-panel">{{item}}年 <label class="ml-2">枠選択済み</label></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div>
                                <div class="mb-3" style="display: flex;">
                                    <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus">宿泊開始日</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus">宿泊最終日</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus">日数</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus">料金(税込)</label>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus-data">{{item}}/{{monthsStartSelected}}/{{daysStartSelected}}</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus-data">{{item}}/{{monthsEndSelected}}/{{daysEndSelected}}</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                         <label class="ml-5 text-cus-data">{{DiffDays}}日間</label>
                                    </div>
                                     <div style="width: 15%;text-align: center;">
                                        <label class="ml-5 text-cus-data">{{changedisplayValue(allPrice)}}</label>
                                    </div>
                                </div>
                                <div>
                                    <v-btn class="t-btn--prm v-btn mr-4 text-14px mt-5 ml-5 btn-cus" @click="isShowFrameSelection = true">
                                        枠選択
                                    </v-btn>
                                </div>
                        </div>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
              </div>
      </slot>
      <div class="footer" style="width: 95%;">
        <slot name="footer">
             <div>  
                  <template>
                    <v-btn small class="t-btn--red-dark btn-cus-cancel" @click="resetForm">
                    <v-icon>mdi-close</v-icon>
                    <span>&emsp;閉じる</span>
                    </v-btn>
                </template>

                <template>
                    <v-btn small class="t-btn--prm ml-3 btn-cus-next" @click="Save()">
                    契約を作成
                    </v-btn>
                </template>               
            </div>
        </slot>
      </div>
      </v-form>
    </v-dialog>
    
    <FrameSelectionBooking v-if="isShowFrameSelection"
     :isShowFrameSelection.sync="isShowFrameSelection"
     :facilityIdList="facilityIdList" 
     :defaultFacilityId="facilityIdList[0]"
     :fromDateDefault="fromDateDefault"
     :toDateDefault="toDateDefault"
     />
  </div>
 
</template>

<script>
import { checkNumber } from '@/utils/validate';
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import CurrencyInput from '@/components/Input/CurrencyInput.vue';
import FrameSelectionBooking from './FrameSelectionBooking.vue';
export default {
  components: {
    FrameSelectionBooking,
  },
  name: 'VMConvertIntoConfirmedContract',
  props: {
    visible: Boolean,
    className: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 500,
    },
  },
  data() {
    return {
        checkIdFacilityNReg: false, 
        checkIdRoomTypeNReg: false,
        fromDateDefault: "",
        toDateDefault: "",
        facilityIdList: [],
        isShowFrameSelection: false,
        title: 'VM契約作成',
        showPrice: false,
        itemsPrice:[],
        itemsPriceArr:[],
        ArrayYears:[],
        ArrayDuplicate:['1年','2年','3年','4年','5年','6年','7年','8年','9年','10年'],
        itemsData: {
            // 連絡先発送先
            clientContactType: [
            { id: null, name: '全て' },
            { id: 'HomeOrAddress', name: '自宅（個人）会社情報（法人）' },
            { id: 'WorkplaceOrNotary', name: '勤務先（個人）記名人（法人）' },
            { id: 'Contact', name: '連絡先（個人・法人）' },
            { id: 'Other', name: 'その他（個人・法人）' },
            ],
        },
        filterData: {
            contactPostalCodes: null,
            contactLocalities: null,
        },
        timeArray:[],
        months:[
            { id: 1 , name: '1'},
            { id: 2 , name: '2'},
            { id: 3 , name: '3'},
            { id: 4 , name: '4'},
            { id: 5 , name: '5'},
            { id: 6 , name: '6'},
            { id: 7 , name: '7'},
            { id: 8 , name: '8'},
            { id: 9 , name: '9'},
            { id: 10 , name: '10'},
            { id: 11 , name: '11'},
            { id: 12 , name: '12'},
        ],
        daysStartArr:[],
        daysEndArr:[],
        monthsStart:null,
        monthsEnd:null,
        daysStart:null,
        daysEnd:null,
        yearStart:null,
        duplicateStart:'',
        allPrice:0,
        rules: {
            number: [
            v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
            v =>
                (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
            ],
            numberCurrency: [
            v =>
                (v && checkNumber(v.replace(/¥|,/g, ''))) ||
                !v ||
                this.$t('rules.numberInvalid'),
            v =>
                (v && parseInt(v.replace(/¥|,/g, '')) <= 2147483647) ||
                !v ||
                this.$t('rules.maximumNumberInt32'),
            ], 
            required: [v => !!v || '必須項目です'],
        },
        arryFromat:[],
        contractID:null,
        yearSelected: null,
        duplicateSelected: null,
        daysStartSelected:null,
        daysEndSelected:null,
        monthsStartSelected:null,
        monthsEndSelected:null,
        ListPanel:[],
        isTN: false,
        dataTN:null,
        DiffDays: null,
        bookingsDay:[],
        checkInDate:null,
        checkOutDate:null,
        checkDone:false,
        reloadStart:false,
        showListDateTime:false,
        data:null,
        arryFromatFill:[],
    };
  },
  created() {
    this.CreateArrayYears();
    this.getFacilityListFee();
    this.getDaysByMonthStart(this.monthsStart);
    this.getDaysByMonthEnd(this.monthsEnd);
    this.getListDay(this.daysStart,this.monthsStart,this.daysEnd,this.monthsEnd);
    this.getPanel();
    this.yearSelected = this.yearStart
  },
  mounted() {
   
  },
  computed: {
    ...mapGetters(['facilityListNotificationRegistration','roomTypeListNReg','idFacilityNReg','idRoomTypeNReg','getVMConvertIntoConfirmedContract']),
    displayValue: {
      get() {
        if (this.value) {
          return (
            '¥' +
            this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          );
        }
        return null;
      },
      set(modifiedValue) {
        let newValue = parseInt(modifiedValue.replace(/[^\d\\.]/g, ''));
        if (isNaN(newValue)) {
          newValue = null;
        }
        this.$emit('input', newValue);
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    getIdFacilityNReg: {
      get() {
        return this.idFacilityNReg;
      },
      set(value) {
        this.setIdFacilityNReg(value);
        this.getRoomTypeListNotificationRegistration();
      },
    },
    getIdRoomTypeNReg: {
      get() {
        return this.idRoomTypeNReg;
      },
      set(value) {
        this.setIdRoomTypeNReg(value);
      },
    },
  },
  methods: {
    ...mapActions(['VMConvertIntoConfirmedContract','getClientBasicContractIDInfo', 'getFacilityListFee','getRoomTypeListNotificationRegistration','getFacilityListFee']),
    ...mapMutations(['setIdFacilityNReg','setIdRoomTypeNReg','setAlertSuccess','setVMConvertIntoConfirmedContract']),
    closeDialog() {
      this.$emit('close');
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.ListPanel = null;
      this.timeArray = [];
      this.isTN = false;
      this.setVMConvertIntoConfirmedContract(false);
      this.showPrice= false
      this.itemsPrice = []
      this.itemsPriceArr= []
      this.daysStartArr=[]
      this.daysEndArr=[]
      this.monthsStart=null
      this.monthsEnd=null
      this.daysStart=null
      this.daysEnd=null
      this.yearStart=null
      this.duplicateStart=''
      this.allPrice=0
      this.arryFromat=[]
      this.contractID=null
      this.yearSelected= null
      this.duplicateSelected= null
      this.daysStartSelected=null
      this.daysEndSelected=null
      this.monthsStartSelected=null
      this.monthsEndSelected=null
      this.ListPanel=[]
      this.isTN= false
      this.dataTN=null
      this.DiffDays= null
      this.bookingsDay=[]
      this.checkInDate=null
      this.checkOutDate=null
      this.checkDone=false
      this.reloadStart=false
      this.showListDateTime=false
      this.data=null
      this.arryFromatFill=[]
      this.$emit('close');
    },
    isShow () {
      if (this.yearStart && this.duplicateStart && this.getIdFacilityNReg && this.getIdRoomTypeNReg && this.monthsStart && this.daysStart && this.monthsEnd && this.daysEnd) {
        return true
      } else {
        return false
      }
    },
    CreateArrayYears(){
      for (let i = 0; i <= 30; i++) {
        this.ArrayYears.push(2021 + i)
      }
    },
    getPriceByDay(valEvent,idx){
      if (valEvent != null) {
        if(valEvent !== "") {
          if (checkNumber(valEvent) === false) {
            valEvent = valEvent.split('¥')[1].split(',').join('');
          }
          if (valEvent) {
            if (checkNumber(valEvent) === true) {
              const ojbf = {
                  id: idx,
                  value: this.changedisplayValue(valEvent)
              }
              if (this.arryFromat.length === 0) {
                  this.arryFromat.push(ojbf);
              } else {
                  if (this.arryFromat.filter(x=>x.id === idx).length <= 0 ) {
                    if (this.arryFromat.length < this.DiffDays) {
                      this.arryFromat.push(ojbf);
                    }
                  } else {
                      this.arryFromat.filter(x=>x.id === idx)[0].value = ojbf.value
                  }
              }
              if (this.itemsPrice.length === 0) {
                  for (let j = 0; j < this.DiffDays; j++) {
                      this.itemsPrice.push(null)
                  }
                  this.arryFromat.forEach(element => {
                      if (element.id === idx) {
                          this.itemsPrice[idx] = element.value
                      }
                  });
              } else {
                  if (this.itemsPrice[idx]) {
                      this.arryFromat.forEach(element => {
                          if (element.id === idx) {
                              this.itemsPrice[idx] = element.value
                          }
                      });
                  } else {
                      this.itemsPrice[idx] = ojbf.value
                  }
              }
              // total price
              this.allPrice = 0;
              this.bookingsDay=[]
              this.arryFromat.forEach(element => {
                this.allPrice +=parseInt(element.value.split('¥')[1].split(',').join(''));
                const objPrice = {
                    inventoryTypeId : this.DiffDays,
                    stayRightPrice:parseInt(element.value.split('¥')[1].split(',').join(''))
                }
                this.bookingsDay.push(objPrice);
              });
              this.arryFromat = this.arryFromat.sort(function(a, b) { 
                  return a.id - b.id;
              });
            }
          }
        } else {
          const ojb = this.arryFromat.filter(function(item) {
              return item.id === idx
          });
          if (ojb.length > 0) {
            this.allPrice -=parseInt(ojb[0].value.split('¥')[1].split(',').join(''));
          }
          this.arryFromat = this.arryFromat.filter(function(item) {
              return item.id !== idx
          });
          for (let i = 0; i < this.itemsPrice.length; i++) {
            if (i === idx) {
              this.itemsPrice[i] = null;
            }
          }
          this.bookingsDay=this.bookingsDay.filter(function(item) {
              return item.inventoryTypeId !== idx
          });
        }
      }
    },
    changedisplayValue(val){
      if(checkNumber(val)) {
        return (
            '¥' +
           parseFloat(val).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          );
      }
    },
    getArrPrice(){
      if (this.data) {
        this.arryFromatFill = []
        this.arryFromat = []
        this.bookingsDay = []
        for (let i = 0; i < this.data.split('\n').length; i++) {
          this.arryFromatFill.push(this.data.split('\n')[i].replace(/[^\d]/g, ''))
          this.getPriceByDay(this.data.split('\n')[i].replace(/[^\d]/g, ''),i);
        }
      } else {
        this.allPrice = 0;
        for (let i = 0; i < this.itemsPrice.length; i++) {
          this.itemsPrice[i] = null;
        }
      }
      this.itemsPriceArr = this.arryFromat
      this.arryFromatFill.forEach((item,idx) => {
         if (item === ""){
          this.itemsPrice[idx] = ""
         }
      });
      // this.allPrice = 0;
      // let priceTN = 0;
      // if (this.arryFromatFill.length === this.DiffDays) {
      //   priceTN = parseInt(this.arryFromatFill[this.arryFromatFill.length -1]);
      // } else if (this.arryFromatFill.length > this.DiffDays) {
      //   priceTN = parseInt(this.arryFromatFill[this.DiffDays -1]);
      // }
      // this.allPrice +=priceTN
      // for (let i = 0; i < this.DiffDays - 1; i++) {
      //   if (this.arryFromatFill[i] !== ""){
      //     this.allPrice += parseInt(this.arryFromatFill[i]);
      //   }
      // }
    },
    getDaysInMonth(m, y) {
        return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
    },
    getYear(year){
        this.yearSelected = year
        this.getListDay(this.daysStart,this.monthsStart,this.daysEnd,this.monthsEnd)
    },
    getDuplicateYear(duplicate){
        this.duplicateSelected = duplicate.split('年')[0]
        this.getPanel();
    },
    getDaysByMonthStart(event){
        this.daysStartArr=[]
        this.arryFromat = []
        this.itemsPrice = []
        this.bookingsDay= []
        this.allPrice = 0;
        for (let i = 1; i <= this.getDaysInMonth(event,this.yearSelected); i++) {
            this.daysStartArr.push(i)
        }
        this.getArrPrice();
        this.getListDay(this.daysStart,event,this.daysEnd,this.monthsEnd)
    },
    getDaysByMonthEnd(event){
        this.arryFromat = []
        this.itemsPrice = []
        this.bookingsDay= []
        this.daysEndArr = []
        this.allPrice = 0;
        for (let i = 1; i <= this.getDaysInMonth(event,this.yearSelected); i++) {
            this.daysEndArr.push(i)
        }
        this.getArrPrice();
        this.getListDay(this.daysStart,this.monthsStart,this.daysEnd,event)
    },
    getdaysStartSelected(event){
        this.arryFromat = []
        this.itemsPrice = []
        this.bookingsDay= []
        this.allPrice = 0;
        this.daysStart = event
        this.getArrPrice();
        this.getListDay(this.daysStart,this.monthsStart,this.daysEnd,this.monthsEnd)
    },
    getdaysEndSelected(event){
        this.arryFromat = []
        this.itemsPrice = []
        this.bookingsDay= []
        this.allPrice = 0;
        this.daysEnd = event
        this.getArrPrice();
        this.getListDay(this.daysStart,this.monthsStart,this.daysEnd,this.monthsEnd)
    },
    getListDay(dt,mt,de,me){
        this.showListDateTime = false;
        this.daysStartSelected = dt < 10 ? '0'+dt : dt;
        this.monthsStartSelected = mt <10 ? '0'+mt : mt;
        this.daysEndSelected = de < 10 ? '0'+de : de;
        this.monthsEndSelected=me < 10 ? '0'+me : me;

        const date1 = new Date(this.monthsStartSelected+'/'+this.daysStartSelected+'/'+this.yearSelected);
        const date2 = new Date(this.monthsEndSelected+'/'+this.daysEndSelected+'/'+this.yearSelected);
        const countDays = this.getDaysInMonth(2,this.yearSelected);
        if (countDays === 29) {
          const dateTN = new Date('02/29/'+this.yearSelected);
          if (mt <=2 && me >= 2) {
            if ((dateTN - date1) >= 0 && (date2 - dateTN) >= 0){
              this.isTN = true;
            }
          }
        }
        const diffTime = Math.abs(date2 - date1);
        if ((date2 - date1) > 0) {
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          this.DiffDays = diffDays + 1 ;
          this.timeArray=[];
          const Firstojb = {
            value: (mt < 10 ? '0'+mt : mt)+"月"+(dt < 10 ? '0'+dt : dt)+"日料金(税込)"
          }
          if (Firstojb.value !== "02月29日料金(税込)"){
            this.timeArray.push(Firstojb)
          }
          for (let index = 0; index < diffDays; index++) {
              const dateCur = new Date(date1.setDate(date1.getDate() + 1)).toLocaleDateString('en-US').split('/');
              const ojb = {
                  value: (dateCur[0] < 10 ? '0'+dateCur[0] : dateCur[0])+"月"+(dateCur[1] < 10 ? '0'+dateCur[1] : dateCur[1])+"日料金(税込)"
              }
              if (ojb.value !== "02月29日料金(税込)"){
                this.timeArray.push(ojb)
              }
          }
        }
        if(this.yearSelected && this.duplicateStart) {
          this.showListDateTime = true;
          this.getPanel();
          this.fromDateDefault =this.yearSelected +'-'+this.monthsStartSelected+'-'+this.daysStartSelected
          this.toDateDefault =this.yearSelected +'-'+this.monthsEndSelected+'-'+this.daysEndSelected
        }
    },
    getPanel(){
      if (this.showListDateTime === true) {
        this.ListPanel = []
        const l = this.duplicateStart.split('年')[0];
        for (let i = 0; i < l; i++) {
            this.ListPanel.push(this.yearStart + i);
        }
      }
    },
    getFacility(e){
      this.facilityIdList = []
     this.facilityIdList.push(e)
    },
    getRoomType(){
      if (this.getIdRoomTypeNReg) {
        this.setVMConvertIntoConfirmedContract(true)
      }
    },
    Save(){
        this.getClientBasicContractIDInfo(parseInt(localStorage.getItem("id"))).then((data)=>{
            this.contractID = data.data.createVmTentativeContract.id
            this.checkInDate = this.yearSelected+'-'+this.monthsStartSelected+ '-' +(parseInt(this.daysStartSelected) < 10 ? '0'+(parseInt(this.daysStartSelected)) : (parseInt(this.daysStartSelected)));
            this.checkOutDate = this.yearSelected+'-'+this.monthsEndSelected + '-' + (parseInt(this.daysEndSelected) < 10 ? '0'+(parseInt(this.daysEndSelected)) : (parseInt(this.daysEndSelected)));
            var day = new Date(this.checkOutDate);
            var nextDay = new Date(day);
            nextDay = new Date(day.setDate(day.getDate() + 1)).toLocaleDateString('en-US')
            var days = nextDay.split("/")[1];
            var months = nextDay.split("/")[0]
            this.checkOutDate = nextDay.split("/")[2] + '-' + (months < 10 ? '0'+months : months) + '-' +(days < 10 ? '0'+ days : days);          
            const len = this.duplicateSelected ? this.duplicateSelected : this.duplicateStart;
            const ojb = {
                    contractId: this.contractID,
                    roomTypeId: this.getIdRoomTypeNReg,
                    years: parseInt(len.split('年')[0]),
                    bookings: [{
                        checkInDate: this.checkInDate,
                        checkOutDate: this.checkOutDate,
                        days: this.bookingsDay
                    }],
                  tvpPaymentAmount: 1
            }
            this.VMConvertIntoConfirmedContract(ojb).then((res) => {
              this.setAlertSuccess('アクティビティのスケジュールを正常に編集する');
              this.$emit('close');
            });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background-color: #fff;
  padding: 20px;
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
  .v-data-table {
    tbody > tr > td {
      &:nth-child(5) {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-child(6) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(7) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(8) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(9) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(10) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(11) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(12) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
      &:nth-child(13) {
        padding: 0 !important;
        line-height: 36px;
        div:not(:last-child) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
.list-all-container {
    margin-right: 30px;
    margin-left: 30px;
}
.textBlack {
    font-size: 16px;
    color: black;
}
.select-label {
    font-size: 10px;
    color: #000000;
}
.container-cus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 3px;
    opacity: 1;
}
.title-cus{
    font: normal normal 600 10px/15px Noto Sans;
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.v-textarea {
    .v-input__control {
      border-radius: 7px;
    }
    textarea {
      color: #444444 !important;
    }
  }
p{
    margin: 0 !important;
}
}
.page-title-list {
    text-align: left;
    font: normal normal bold 28px/38px Noto Sans;
    font-size: 28px !important;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
}
.status-err{
    color: red;
    font-size: 16px;
}
.year-panel{
    font: normal normal normal 16px/48px Noto Sans;
    letter-spacing: 0px;
    color: #232222;
    opacity: 1;
}
.text-cus{
    text-align: left;
    font: normal normal 600 10px/15px Noto Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    &-data{
        font: normal normal medium 14px/21px Noto Sans;
        letter-spacing: 0px;
        color: #444444;
        opacity: 1;
    }
}
.btn-cus{
    background: #13ACE0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}
.v-btn__content{
    font: normal normal medium 14px/21px Noto Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.btn-cus-cancel{
    background: #C92D2D 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
    width: 72px;
    height: 32px;
    font: normal normal medium 14px/21px Noto Sans;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    
}
.btn-cus-next{
    background: #AAAAAA 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: normal normal medium 14px/21px Noto Sans;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    width: 120px;
    height: 32px;
}
</style>
