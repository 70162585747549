<template>
  <div>
    <!-- <contract-detail></contract-detail> -->
    <VMTemporaryContractTab :name="clientName" />
    <div class="mx-10 d-flex justify-space-between">
      <div>
        <template>
          <div class="d-flex justify-space-between pa-2 div-alert">
            <div class="mt-1">
              <i class="icon-alert fas fa-exclamation-triangle"></i>
              <span class="ml-2 text-alert" style="font-size: 16px !important;">この契約は仮押さえ中です</span>
            </div>
            <div>
              <v-btn
                class="btn-billing btn-crm-primary mr-4"
                style="font-size: 14px !important;"
                @click="dialogChirlden = true"
              >
                仮契約を削除
              </v-btn>
              <v-btn class="btn-billing btn-crm-primary" style="font-size: 14px !important;" @click="redirectPage()">
                本契約に切替
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div>
        <v-btn 
          class="t-btn--red-dark mr-4"
          @click="closeWindow"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('task_29786.close') }}
        </v-btn>
        <v-btn class="t-btn--prm"> 保存 </v-btn>
      </div>
    </div>
    <div class="ml-8 mt-4">
      <v-btn class="t-btn--prm" @click="visible = 1">
        仮押さえ予約を作成
      </v-btn>
      <v-btn class="t-btn--prm mx-5" disabled>
        仮押さえ予約を編集
      </v-btn>
      <v-btn class="t-btn--prm" disabled>
        仮押さえ予約を削除
      </v-btn>
    </div>
    <!-- dialog -->
    <CreateContract :visible="visible === 1" @close="visible = -1" />
    <v-row justify="center">
      <v-dialog v-model="dialogChirlden" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            仮契約を削除?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="t-btn--red-dark" text @click="dialogChirlden = false">
              いいえ
            </v-btn>
            <v-btn
              class="file t-btn--prm"
              style="color: white"
              @click="del()"
              :disabled="!checkPerUser"
            >
              はい
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <VMConvertIntoConfirmedContract 
              :visible="dialog === 1"
              :width="750"
              @close="dialog = -1"
              className="custom-dialog"
          >
       </VMConvertIntoConfirmedContract>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import VMTemporaryContractTab from './VMTemporaryContractTab.vue';
import CreateContract from './CreateContract.vue';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { mapGetters, mapMutations } from 'vuex';
import VMConvertIntoConfirmedContract from './VMConvertIntoConfirmedContract.vue'

export default {
  components: { VMTemporaryContractTab, CreateContract,VMConvertIntoConfirmedContract},
  name: 'VMTemporaryContract',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog:-1,
      name: '',
      visible: -1,
      dialogChirlden: false,
    };
  },
  computed: {
    ...mapGetters([
      'IDVmTentativeContractCustomerMc',
      'currentURL'
    ]),

    clientName: {
      get() {
        return this.name;
      },
      set(val) {
        this.name = val;
      },
    },
  },
  mounted() {
    this.getClientBasicInfo();
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertError',
      'setAlertSuccess',
      'setPermissionUser',
      'setRoleAdminUser',
                'setYearsTemContract',
          'setNumYearTemContract',
          'setFromMonthTemContract',
          'setFromDateTemContract',
          'setToMonthTemContract',
          'setToDateTemContract',
          'setFacilityIdTemContract',
          'setRoomtypeIdTemContract',
    ]),

    async del() {
      this.$store.commit('setLoadingOverlayShow', { root: true })
      const query = `
            mutation ($contractId: Int!) {
              deleteTentativeContract(id: $contractId) {
                __typename
              }
            }
          `
      const variables = {
            contractId: parseInt(this.$route.query.contractItem)
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          // this.setYearsTemContract(null)
          // this.setNumYearTemContract(null)
          // this.setFromMonthTemContract(null)
          // this.setFromDateTemContract(null)
          // this.setToMonthTemContract(null)
          // this.setToDateTemContract(null)
          // this.setFacilityIdTemContract(null)
          // this.setRoomtypeIdTemContract(null)
          this.setAlertSuccess('削除しました。')
          this.dialogChirlden = false
          this.$store.commit('setLoadingOverlayHide', { root: true })
          window.close();
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
          this.$store.commit('setLoadingOverlayHide', { root: true })
        });
      if (this.flag) return true;
      else return false;
    },

    closeWindow(){
      window.close();
    },

    async getClientBasicInfo() {
      const query = `
            query($id: Int!) {
              clientBasicInfo(id: $id) {
                id
                name
              }
            }
          `
      const variables = {
            id: parseInt(this.$route.params.id),
          }
      await this.$apollo
        .query({
          query: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          this.clientName = data.data.clientBasicInfo.name;
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      // return true
    },
    redirectPage(){
      this.dialog = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
// div
.div-alert {
  color: #ffffff;
  width: 500px;
  margin-left: -6px;
  height: 46px;
  background-color: #cb396c;
  border-radius: 9px;
}
// icon
.icon-alert {
  font-size: 20px;
}
.text-alert {
  font-size: 14px;
}
.btn-billing {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background-color: #cb396c !important;
  width: 110px;
  font-size: 12px !important;
  height: 32px !important;
}
</style>
