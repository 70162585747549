<template>
  <div
    class="t-calendar-item"
    :set="
      (range = getDiff2Days(rowItem.availableFromDate, rowItem.availableToDate))
    "
  >
    <div class="t-calendar-item__title">
      {{ rowItem.title }}
    </div>
    <div class="t-calendar-item__data-list">
      <div
        class="t-calendar-item__data-item--abs"
        :class="getClassObject"
        :style="
          'left: calc(235px + 170px * ' +
          checkFromDateBooking(rowItem.availableFromDate) +
          '); width:' +
          'calc(170px * ' +
          getRangeDateBooking(
            rowItem.availableToDate,
            rowItem.availableFromDate,
          ).length +
          ')'
        "
      >
        <template v-if="!inDay">
          {{
            (checkFromDateBooking(rowItem.availableFromDate) >= 0 &&
              range >= 1 &&
              `${getChargeType} ¥${formatCurrency(
                rowItem.fixedAdditionalCharge,
              )}`) ||
            ''
          }}
        </template>
        <template v-else>
          <Pass
            v-for="el in getRangeDateBooking(
              rowItem.availableToDate,
              rowItem.availableFromDate,
            )"
            :key="el + type + 'day'"
            :metadata="{
              price: findBookingChargeItem(rowItem.bookingPlanCharges, el),
            }"
          >
            <span slot-scope="{ metadata }">
              {{ metadata.price ? '¥' + formatCurrency(metadata.price) : '' }}
            </span>
          </Pass>
        </template>
      </div>
      <div
        v-for="date in dateListAll"
        :key="date"
        class="t-calendar-item__data-item"
        :class="getDateClassObjecct"
      ></div>
    </div>
  </div>
</template>
<script>
import Pass from '@/components/shared/Pass.vue';
import { getAdditionalChargeType } from '@/utils/getAdditionalChargeType';
import { formatCurrency } from '@/constants/functions';
import { getDaysArray, getDiff2Days } from '@/utils/dateUtil';

export default {
  name: 'BookingPlanItem',
  components: { Pass },
  props: {
    type: String,
    rowItem: Object,
    fromDate: String,
    dateListAll: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    inDay() {
      return ['PersonDay', 'RoomDay'].includes(this.additionalChargeType);
    },
    additionalChargeType() {
      return this.rowItem.additionalChargeType;
    },
    getChargeType() {
      return getAdditionalChargeType(this.additionalChargeType) + ' | ';
    },
    getDateClassObjecct() {
      const type = this.type;

      return {
        'bg--vs': type === 'VS',
        'bg--vm': type === 'MO',
        'bg--sp': type === 'SP',
        'bg--fp': type === 'FP',
      };
    },
    getClassObject() {
      const type = this.type;

      return {
        '--multi': this.inDay,
        'bg--booking-vs': type === 'VS',
        'bg--booking-mo': type === 'MO',
        'bg--booking-fp': type === 'FP',
        'bg--booking-dp': type === 'SP',
      };
    },
  },
  methods: {
    getAdditionalChargeType,
    formatCurrency,
    getDiff2Days,
    checkFromDateBooking(fromDate) {
      const timeFromDate = new Date(fromDate).getTime();
      const timeFromDateCurrent = new Date(this.fromDate).getTime();

      if (timeFromDate <= timeFromDateCurrent) return 0;
      return getDiff2Days(fromDate, this.fromDate);
    },
    getRangeDateBooking(toDate, fromDate) {
      const rangeDate = getDaysArray(new Date(fromDate), new Date(toDate));
      return this.dateListAll.filter((e) => rangeDate.indexOf(e) !== -1);
    },
    findBookingChargeItem(chargeList, stayDate) {
      const item = chargeList.find((el) => el.stayDate === stayDate);
      if (item) return item.fixedAdditionalCharge;
      return '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
