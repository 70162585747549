// components
import Pass from '@/components/shared/Pass.vue';
import {
  INVENTORY_LIST,
} from '@/api/graphql/inventory/inventory';
// utils
import {
  getDiff2Days,
} from '@/utils/dateUtil';
// lodash
import { sortBy, chain } from 'lodash';
import gql from 'graphql-tag';

export const bookingMixin = {
  components: {
    Pass,
  },
  data() {
    return {
      // cells
      selectedReservationRooms: [],
    };
  },
  computed: {
    getSelectedReservationRoomDates() {
      return sortBy(this.selectedReservationRooms.map((ele) => ele.date));
    },
    getSelectedReservationRoomId() {
      return this.selectedReservationRooms.length > 0
        ? this.selectedReservationRooms[0].roomTypeId
        : null;
    },
  },
  methods: {
    // ================= HANDLE DATA ========================
    async getInventoryList() {
      this.setLoadingOverlayShow();

      const facilityId = this.selectedFacility.id;
      await this.$apollo
        .query({
          query: gql`${INVENTORY_LIST}`,
          variables: {
            dateFrom: this.fromDate,
            dateTo: this.toDate,
            facilityId: facilityId,
            roomTypes: this.roomTypeList
              .filter((roomType) =>
                facilityId ? roomType.facilityId === facilityId : true,
              )
              .map((room) => room.id),
            // inventoryTypes: [4]
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const list = res.data.inventoryList;

          const result = chain(list)
            .groupBy('roomTypeId')
            .map((value, key) => ({ roomTypeId: key, calendar: value }))
            .value();

          result.forEach((el) => {
            el.calendar = chain(el.calendar)
              .groupBy('stayDate')
              .map((value, key) => ({ date: key, item: value }))
              .value();
          });

          this.inventoryList = result;
        })
        .finally(() => this.setLoadingOverlayHide());
    },
    getMwRank(date) {
      const mwWeek = this.mwWeekCalendarList.find(
        (mwWeek) => mwWeek.mwDate === date,
      );

      return mwWeek ? mwWeek.mwRank : '';
    },
    // ================= HANDLE BOOKING DATA ========================
    /**
     *
     * @param {*} roomTypeId
     * @param {*} date
     * @param {*} index
     * @param {*} isSelected
     */
     selectReservationRoom(roomTypeId, inventoryTypeId, roomTypeName, date, index, isSelected) {
      if (!isSelected) {
        if (this.checkOutRangeCheckOut(date, this.toDateSelected) === false) {
          this.selectedReservationRooms.push({
            roomTypeId: roomTypeId,
            inventoryTypeId: inventoryTypeId,
            roomTypeName: roomTypeName,
            date: date,
            index: index,
          });
        }
      } else {
        this.selectedReservationRooms = this.selectedReservationRooms.filter(
          (ele) =>
            !(
              ele.roomTypeId === roomTypeId &&
              ele.date === date &&
              ele.index === index
            ),
        );
      }
    },
    checkOutRangeCheckOut(dateSelected, checkOut){
      if ((new Date(checkOut) - new Date(dateSelected)) >= 0) {
        return false
      } else {
        return true
      }
    },
    isSeletedCell(roomTypeId, date, index) {
      return !!this.selectedReservationRooms.find(
        (ele) =>
          ele.roomTypeId === roomTypeId &&
          ele.date === date &&
          ele.index === index,
      );
    },
    isValidCell(roomTypeId, date, index) {
      if (getDiff2Days(this.fromDate, date) >= 0 && getDiff2Days(date, this.toDateDefault) >= 0) {
        const selectedRoomTypeId = this.getSelectedReservationRoomId;
        if (!selectedRoomTypeId) return true;
        else if (roomTypeId !== selectedRoomTypeId) return false;

        const dates = this.getSelectedReservationRoomDates;
        const minDate = dates[0];
        const maxDate = dates[dates.length - 1];
        
        const existDate = this.selectedReservationRooms.find(
          (ele) => ele.date === date,
        );

        if (existDate) {
          if (existDate.index !== index) return false;
          else if (date === minDate || date === maxDate) return true;
          return false;
        }

        if (
          Math.abs(getDiff2Days(minDate, date)) === 1 ||
          Math.abs(getDiff2Days(date, maxDate)) === 1
        ) {
          return true;
        }
        return false;
      } else {
        return false;
      }
      
    },
    resetReservationRooms() {
      this.selectedReservationRooms = [];
    },
    /**
     *
     * @param {*} bookings
     * @param {*} roomName
     * @returns
     */
    checkDuplicateBooking(bookings, roomName) {
      let count = 0;
      for (let i = 0; i < bookings.length; i++) {
        if (roomName === bookings[i].room.name) {
          count = count + 1;
          if (count > 1) return true;
        }
      }

      return false;
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getBgRoom(id) {
      const roomType = this.roomTypeList.find(
        (roomType) => roomType.id === parseInt(id),
      );
      if (roomType) return roomType.backgroundColor;
      return '';
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getNameRoomType(id) {
      const roomType = this.roomTypeList.find(
        (roomType) => roomType.id === parseInt(id),
      );

      if (roomType) return roomType.name;
      return '';
    },
  },
};
