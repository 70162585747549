import gql from 'graphql-tag'

export const ACCOMMODATION_PRICE_LIST = gql`
  query(
    $facilityId: Int
    $accommodationFromDate: String!
    $accommodationToDate: String!
    $nonProductFromDate: DateString!
    $nonProductToDate: DateString!
    $mwWeekCalendarFromDate: DateString
    $mwWeekCalendarToDate: DateString
    $bookingPlanFilter: BookingPlanListFilterInput!
    $bookingPlanPagination: PaginationInput
  ) {
    accommodationPriceList(
      fromDate: $accommodationFromDate
      toDate: $accommodationToDate
      facilityId: $facilityId
    ) {
      id
      roomTypeId
      stayDate
      spPoint
      fpPoint
      dpPrice
      vmPrice1
      vmPrice2
      vmPrice3
      vmPrice4
      vmPrice5
      vmPrice6
      vmPrice7
      vmPrice8
      vmPrice9
      vmPrice10
      vsPrice1
      vsPrice2
      vsPrice3
      vsPrice4
      vsPrice5
      vsPrice6
      vsPrice7
      vsPrice8
      vsPrice9
      vsPrice10
    }
    nonProductPriceList(
      fromDate: $nonProductFromDate
      toDate: $nonProductToDate
      facilityId: $facilityId
    ) {
      roomTypeId
      stayDate
      price1
      price2
      price3
      price4
      price5
      price6
      price7
      price8
      price9
      price10
    }
    mwWeekCalendarList(
      fromDate: $mwWeekCalendarFromDate
      toDate: $mwWeekCalendarToDate
      facilityId: $facilityId
    ){
      facilityId
      mwDate
      year
      weekNo
      latterHalf
      mwRank
    }
    bookingPlanList(
      filter: $bookingPlanFilter
      pagination: $bookingPlanPagination
    ) {
      items {
        roomTypeId
        enabled
        targetBookingType
        title
        id
        minDays
        maxDays
        minGuestCount
        maxGuestCount
        availableFromDate
        availableToDate
        additionalChargeType
        fixedAdditionalCharge
        bookingPlanCharges {
          stayDate
          fixedAdditionalCharge
        }
      }
    }
  }
`