<template>
  <div class="d-flex justify-space-around vm-stats-data-item">
    <div>
      <span v-if="item">{{ item.roomsCount }}</span>
      <span v-else>-</span>
    </div>
    <div>
      <span v-if="item"
        :style="
          item.availableRoomsCount <= 0
            ? 'color:#FF0000 !important'
            : 'color:#757575 !important'
        "
        >{{ item.availableRoomsCount }}</span
      >
      <span v-else>-</span>
    </div>
    <div>
      <span v-if="item">{{ item.bookedVmRoomsCount }}</span>
      <span v-else>-</span>
    </div>
    <div>
      <span v-if="item">{{ Math.round(item.ratio * 100) }}%</span>
      <span v-else>-</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
    }
  }
}
</script>
<style lang="scss" scoped>
.vm-stats-data-item {
  &>div {
    min-width: 50px;
    text-align: center;
  }
}
</style>