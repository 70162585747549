<template>
  <div>
    <template v-if="showTheRestOfForm && roomType" >
      <VMNewContractInventoryPrices
        v-if="!tentative"
        v-model="priceByMonthDate"
        :tvpPaymentAmount.sync="tvpPaymentAmount"
        :bookings="bookings"
      />

      <VMNewContractInventorySelect 
        :bookings="bookings"
        :room-type="roomType"
        :contractId="contractId"
        @selected="handleSelected"
      />

    </template>

    <!-- button -->
    <div class="d-flex justify-end mt-5">
      <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
        {{ $t('task_29786.close') }}
      </v-btn>
      <v-btn
        v-if="tentative"
        @click="confirm7Days(() => createTemporaryBookings())"
        class="t-btn--prm"
        style="background: #009ce6 !important"
        :disabled="!allInventorySelected"
      >
        VM仮押さえ契約作成
      </v-btn>
      <v-btn
        v-else
        @click="confirm7Days(() => createConfirmedBookings())"
        class="t-btn--prm"
        style="background: #009ce6 !important"
        :disabled="!allInventorySelected || !allPricesCompleted"
      >
        VM契約を作成
      </v-btn>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import VMNewContractInventorySelect from './VMNewContractInventorySelect.vue';
import { createISODateFromYMD, calculateStayDays, getMonthDateString } from '@/utils/dateUtil';
import VMNewContractInventoryPrices from './VMNewContractInventoryPrices.vue';

export default {
  components: { VMNewContractInventorySelect, VMNewContractInventoryPrices },
  props: {
    contractId: Number,
    roomTypeId: Number,
    fromYear: Number,
    years: Number,
    fromMonth: Number,
    fromDate: Number,
    toMonth: Number,
    toDate: Number,
    tentative: Boolean,
  },
  data() {
    return {
      roomType: null,
      bookings: [],
      priceByMonthDate: {},
      tvpPaymentAmount: 0,
    };
  },
  computed: {
    showTheRestOfForm() {
      return this.roomTypeId && this.fromYear && this.years
        && this.fromMonth && this.fromDate && this.toMonth && this.toDate
    },
    yearArray() {
      return [...Array(this.years)].map((_, ind) => this.fromYear + ind);
    },
    toDateYearOffset() {
      const from = new Date(this.fromYear, this.fromMonth - 1, this.fromDate);
      const to = new Date(this.fromYear, this.toMonth - 1, this.toDate);
      if (from.getTime() >= to.getTime()) {
        // this means that the checkout is in the next year!
        return 1;
      }
      return 0;
    },
    minStayDays () {
      if (this.yearArray.length === 0) return null;
      return this.yearArray.reduce((min, year) => {
        const checkInDate = createISODateFromYMD(year, this.fromMonth, this.fromDate);
        const checkOutDate = createISODateFromYMD(year + this.toDateYearOffset, this.toMonth, this.toDate);
        const stayLength = calculateStayDays(checkInDate, checkOutDate);
        return Math.min(min, stayLength)
      }, 1000)
    },
    bookingTemplate() {
      return this.yearArray.map((year) => {
        const checkInDate = createISODateFromYMD(year, this.fromMonth, this.fromDate);
        const lastStayDate = createISODateFromYMD(year + this.toDateYearOffset, this.toMonth, this.toDate - 1);
        const checkOutDate = createISODateFromYMD(year + this.toDateYearOffset, this.toMonth, this.toDate);
        const stayLength = calculateStayDays(checkInDate, checkOutDate);
        return {
          checkInDate,
          checkOutDate,
          lastStayDate,
          stayLength
        };
      });
    },
    allInventorySelected() {
      return this.bookings.every(booking => {
        return booking.days?.length === booking.stayLength
      })
    },
    allPricesCompleted() {
      const nonNulls = Object.values(this.priceByMonthDate).filter(price => typeof price === 'number').length;
      return nonNulls > 0 && Object.keys(this.priceByMonthDate).length === nonNulls && this.tvpPaymentAmount >= 0
    }
  },
  watch: {
    bookingTemplate: {
      immediate: true,
      handler(bookings) {
        this.bookings = bookings.slice()
      }
    }
  },
  methods: {
    async confirm7Days (action) {
      const ok = () => action()
      if (this.minStayDays < 7) {
        this.$confirm({
          title: '7日未満VM契約',
          message: `契約する連泊数が7日未満となっていますが${this.tentative ? '仮押さえ' : '本契約'}を行いますか？`,
          ok,
        })
      } else {
        ok()
      }
    },
    async createTemporaryBookings() {
      await this.$viewMutate({
        query: `mutation (
            $contractId: Int!
            $roomTypeId: Int! 
            $years: Int! 
            $bookings: [CreateVmBookingInput!]! 
          ) {
            createVmTentativeBookings(
              contractId: $contractId
              roomTypeId: $roomTypeId
              years: $years
              vmBookings: $bookings
            ) {
              __typename
            }
          }`,
        variables: {
          contractId: this.contractId,
          roomTypeId: this.roomTypeId,
          years: this.years,
          bookings: this.bookings.map(b => ({
            checkInDate: b.checkInDate,
            checkOutDate: b.checkOutDate,
            days: b.days
              .slice()
              .sort((a, b) => new Date(a.stayDate).getTime() - new Date(b.stayDate).getTime())
              .map(day => ({
                inventoryTypeId: day.inventoryTypeId,
                stayRightPrice: this.priceByMonthDate[getMonthDateString(day.stayDate)]
              }))
          }))
        }
      })
      this.$emit('created')
    },
    async createConfirmedBookings() {
      await this.$viewMutate({
        query: `mutation (
            $contractId: Int!
            $roomTypeId: Int! 
            $years: Int! 
            $bookings: [CreateVmBookingInput!]! 
            $tvpPaymentAmount: Int!
          ) {
            convertIntoConfirmedContract(
              contractId: $contractId
              roomTypeId: $roomTypeId
              years: $years
              vmBookings: $bookings
              tvpPaymentAmount: $tvpPaymentAmount
            ) {
              __typename
            }
          }`,
        variables: {
          tvpPaymentAmount: this.tvpPaymentAmount, // then the 利用確認ボタン text is missing , stats table
          contractId: this.contractId,
          roomTypeId: this.roomTypeId,
          years: this.years,
          bookings: this.bookings.map(b => ({
            checkInDate: b.checkInDate,
            checkOutDate: b.checkOutDate,
            days: b.days
              .slice()
              .sort((a, b) => new Date(a.stayDate).getTime() - new Date(b.stayDate).getTime())
              .map(day => ({
                inventoryTypeId: day.inventoryTypeId,
                stayRightPrice: this.priceByMonthDate[getMonthDateString(day.stayDate)]
              }))
          }))
        }
      })
      this.$emit('created')
    },
    closeDialog() {
      this.$emit('close')
    },
    handleSelected({
      index, booking
    }) {
      this.$set(this.bookings, index, booking)
    }
  },
  apollo: {
    roomType: {
      query: gql`
      query ($id: Int!) {
        roomType(id: $id) {
          id
          facilityId
          name 
          facility { name }
        }
      }
      `,
      variables() {
        return {
          id: this.roomTypeId
        }
      },
      skip() {return !this.roomTypeId; },
      update: (data) => data.roomType,
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}

::v-deep {
  .v-dialog {
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  td {
    word-break: keep-all !important;
    padding: 0 5px;
    text-align: center;
    min-width: 50px;
  }
  th {
    border-right: 1px solid #dedddf;
  }
}
</style>
