// components
import BookingPlanItem from './components/BookingPlanItem';
import AccomItem from './components/AccomItem';
// apollo
import { apolloClient } from '@/plugins/apollo';
// api
import { ACCOMMODATION_PRICE_LIST } from '@/api/graphql/accommodation/getAccomodationPriceList';
import { FACLILITY_LIST } from '@/api/graphql/corporateTicket/new-ticket';
// utils
import { formatDate, formatMonth } from '@/constants/functions';
import {
  addMonths,
  getDate,
  getAllDateInBetween2Dates,
  getTotalDaysInMonth,
  getDayInMonth,
} from '@/utils/dateUtil';
// vuex
import { mapMutations,mapGetters } from 'vuex';
// mixin, 
import { bookingMixin } from './booking.mixin';
import gql from 'graphql-tag';

export const mixin = {
  mixins: [bookingMixin],
  components: {
    BookingPlanItem,
    AccomItem,
  },
  name: 'FeesAndPoints',
  timeRange: [
    {
      text: '1ヶ月',
      value: 1,
    },
    {
      text: '3ヶ月',
      value: 3,
    },
    {
      text: '6ヶ月',
      value: 6,
    },
  ],
  data() {
    return {
      listfacility: [],
      // date queries
      rangeTime: 1,
      fromDateCurrent: this.fromDateDefault,
      // toDate: addMonths(new Date(this.fromDateDefault).toISOString().substr(0, 10), 1),
      toDate: '' ,
      menuDate: false,
      // facility
      facilityList: [],
      selectedFacility: {
        id: null,
        name: '全て',
      },
      // room
      roomTypeList: [],
      accommodationPriceList: [],
      bookingPlanList: [],
      // inventory
      inventoryList: [],
      // mwWeek
      mwWeekCalendarList: [],
      mwWeekNo: {},
    };
  },

  async mounted() {
    this.setDefault()
    await this.fetchFacilityList();
    this.resetAndGetNewData();
    const facility = this.facilityList.find(
      (facility) => facility.id === this.defaultFacilityId,
    );
    if (facility) this.selectedFacility = facility;
  },
  computed: {
    ...mapGetters(['getSelectItem']),
    facilityListItem() {
      const copied = this.facilityList ? this.facilityList.slice() : [];
      return copied;
    },
    fromDate: {
      get() {
        return this.fromDateCurrent;
      },
      set(value) {
        this.fromDateCurrent = value;
        if (new Date(addMonths(value, this.rangeTime)) - new Date(this.toDateDefault) < 0) {
          this.toDate = addMonths(value, this.rangeTime);
        } else this.toDate = this.toDateDefault
      },
    },
    idSelectedFacility: {
      get() {
        return this.selectedFacility ? this.selectedFacility.id : this.selectedFacility[0].id;
      },
      set(id) {
        const facility = this.facilityList.find((item) => item.id === id);
        this.selectedFacility = facility;
      },
    },
    dateListAll() {
      return getAllDateInBetween2Dates(this.fromDate, this.toDate);
    },
    getMonthsList() {
      const months = [
        {
          value: formatMonth(this.fromDateCurrent),
          style: `width: calc(167px * ${
            getTotalDaysInMonth(this.fromDateCurrent) -
            getDayInMonth(this.fromDateCurrent) +
            1
          })`,
        },
      ];

      for (let i = 1; i <= this.rangeTime; i++) {
        const date = addMonths(this.fromDateCurrent, i);
        months.push({
          value: formatMonth(date),
          style: `width: calc(167px * ${
            i === this.rangeTime
              ? getDayInMonth(this.toDate)
              : getTotalDaysInMonth(date)
          })`,
        });
      }

      return months;
    },
  },
  watch: {
    toDate() {
      this.fetchData();
      this.getInventoryList()
    },
    async selectedFacility() {
      this.resetAndGetNewData();
    },
  },
  methods: {
    formatDate,
    getDate,
    addMonths,
    ...mapMutations(['setLoadingOverlayHide', 'setLoadingOverlayShow', 'saveSelectItem']),
    // ================= HANDLE DATE ========================
    nextMonth() {
      this.fromDate = addMonths(this.fromDate, this.rangeTime);
    },
    prevMonth() {
      this.fromDate = addMonths(this.fromDate, -this.rangeTime);
    },
    chooseToday() {
      this.fromDate = new Date().toISOString().substr(0, 10);
    },
    changeTime(v) {
      this.toDate = this.addMonths(this.fromDate, v);
    },
    // ================= HANDLE DATA ========================
    async resetAndGetNewData() {
      this.resetData();
      await this.getInventoryList();
      this.fetchData();
    },

    setDefault() {
      this.toDate = new Date(addMonths( this.fromDateDefault, 1)) - new Date(this.toDateDefault) < 0 ?  addMonths(this.fromDateDefault, 1) : this.toDateDefault
    },

    async fetchData() {
      apolloClient
        .query({
          query: gql`${ACCOMMODATION_PRICE_LIST}`,
          variables: {
            facilityId: this.idSelectedFacility,
            accommodationFromDate: this.fromDate,
            accommodationToDate: this.toDate,
            nonProductFromDate: this.fromDate,
            nonProductToDate: this.toDate,
            mwWeekCalendarFromDate: this.fromDate,
            mwWeekCalendarToDate: this.toDate,
            bookingPlanFilter: {
              availableFromDate: this.fromDate,
              availableToDate: this.toDate,
              facilityId: this.idSelectedFacility,
            },
          },
          // fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const data = res.data;

          // get roomTypeId from data api
          const roomIds = data.accommodationPriceList
            .map((accom) => accom.roomTypeId)
            .concat(
              data.bookingPlanList.items.map((booking) => booking.roomTypeId),
            );

          // remove duplicate from array
          const uniqueRoomIds = roomIds.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });

          // returns a list of roomTypes sorted by AccommodationPriceList
          this.roomTypeList = uniqueRoomIds.map((id) => {
            let roomIndex = 0;
            const roomType = this.facilityList.find((facility) => {
              roomIndex = facility.roomTypes.findIndex(
                (roomType) => roomType.id === id,
              );

              return roomIndex >= 0;
            });
            if (roomType && roomType.roomTypes[roomIndex]) {
              return roomType.roomTypes[roomIndex];
            }
          });

          this.accommodationPriceList = data.accommodationPriceList;
          this.mwWeekCalendarList = data.mwWeekCalendarList;
          this.bookingPlanList = data.bookingPlanList.items;

          const mwWeekNo = {};
          this.mwWeekCalendarList.forEach((mwWeekCalendar) => {
            const key =
              mwWeekCalendar.weekNo +
              (mwWeekCalendar.latterHalf ? '後半' : '前半');
            const mwDate = mwWeekCalendar.mwDate;
            if (!mwWeekNo[key]) {
              mwWeekNo[key] = [];
              mwWeekNo[key] = [mwDate];
            } else if (!mwWeekNo[key].includes(mwDate)) {
              mwWeekNo[key].push(mwDate);
            }
          });

          this.mwWeekNo = mwWeekNo;
        });
    },
    async fetchFacilityList() {
      await apolloClient
        .query({
          query: gql`${FACLILITY_LIST}`,
        })
        .then((res) => {
          this.listfacility = res.data.facilityList
          const facilityListData = res.data.facilityList
          if(facilityListData.length > 1) {
            for (let j = 0; j < this.facilityIdList.length; j++) {
              const item = facilityListData.find(
                (item) => item.id === this.facilityIdList[j],
              );
              this.facilityList.push(item)
            }
          } else {
            this.facilityList = []
            const item = this.facilityIdList.find(item => item === facilityListData[0].id)
            if (typeof item !== 'undefined' && item !== null) {
              this.facilityList.push(facilityListData[0])
            }
          }
        });
    },

    getRoomTypeName(id) {
      const itemFac = this.listfacility.find(item => item.id === parseInt(this.idSelectedFacility))
      if (itemFac && itemFac.roomTypes) {
        const itemRoomType = (itemFac.roomTypes).find(item => item.id === parseInt(id))
        if (itemRoomType && itemRoomType.name) {
          return itemRoomType.name
        } else return ''
      }
    },

    resetData() {
      this.accommodationPriceList = [];
      this.mwWeekCalendarList = [];
      this.bookingPlanList = [];
    },
    // ================= HANDLE ITEM ========================
    filterBookingPlan(roomTypeId, type) {
      return this.bookingPlanList.filter(
        (item) =>
          item.roomTypeId === roomTypeId &&
          item.targetBookingType === type &&
          item.enabled,
      );
    },
    findMWItem(currentDate) {
      const item = this.mwWeekCalendarList.find(
        (item) => item.mwDate === currentDate,
      );

      return item ? item.mwRank : '';
    },
  },
};
