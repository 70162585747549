<template>
  <div>
    <div>
      <div
        class="d-flex justify-space-between px-10"
        style="background-color:#F8F8F8"
      >
        <div>
          <h1 class="my-4 page-title-list">
            {{name}}:{{$route.query.type}}:仮契約
          </h1>
        </div>
      </div>
      <template>
        <v-tabs class="mb-2" v-model="tab" align-with-title>
          <v-tabs-slider color="#13ACE0"></v-tabs-slider>
          <!-- class="page-title-tab" -->
          <!-- :to="item.to" -->
          <v-tab v-for="item in items" :key="item.id" :to="item.to" exact style="font-size: 16px;"
            >{{ item.title }}
          </v-tab>
        </v-tabs>
      </template>
      <!-- <template>
        <div class="d-flex justify-start mb-2">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="#13ACE0"></v-tabs-slider>
            <v-tab
              exact
              class="page-title-tab"
              v-for="(item, i) in items"
              :key="i"
              :to="item.to"
              >{{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
      </template> -->
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'VMTemporaryContractTab',
  props:{
    name: String
  },
  data() {
    return {
      visible: -1,
      // Tabs
      tab: null,
      items: [
        {
          id: 1,
          title: '利用状況',
          to: '',
        },
        {
          id: 2,
          title: '基本情報',
          to: '/customer/prospect-list',
        },
        {
          id: 3,
          title: '契約時情報',
          to: '/customer/member-list',
        },
        // {
        //   id: 4,
        //   title: '契約解除',
        //   to: '/customer',
        // },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scope>
.v-tab:not(.v-tab--active) {
  background-color: #ffffff;
  color: #aaaaaa !important;
  &:hover {
    background-color: #eeeeee;
    color: #13ace0 !important;
  }
}
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}
.color-title {
  background-color: #f8f8f8 !important;
}

.button-header {
  span {
    color: #ffffff !important;
  }
}
</style>
