<template>
  <div>
    <v-dialog v-model="dialog" :width="dialogWidth" persistent>
      <v-form ref="form">
        <div class="d-flex justify-space-between">
          <h1>VM仮押さえ契約作成</h1>
          <div class="d-flex justify-end" v-if="step === 1">
            <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
              {{ $t('task_29786.close') }}
            </v-btn>
            <v-btn
              @click="step=2"
              :disabled="checkNullInput"
              class="t-btn--prm"
            >
              次へ
            </v-btn>
          </div>
        </div>
        <!-- select first -->
        <div class="mt-5">
          <div class="d-flex justify-start">
            <div style="width: 90px">
              <h6>開始年</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                :items="listYears"
                v-model="years"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              />
            </div>
            <div style="width: 90px">
              <h6>契約年数</h6>
              <v-select
                class="mr-5"
                dense
                v-model="numYear"
                :items="listNumYear"
                :rules="rules.required"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <div style="width: 300px">
              <h6>施設を選択</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                :items="facilityList"
                v-model="facilityId"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <div style="width: 200px">
              <h6>部屋タイプ</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                v-model="roomtypeId"
                :items="roomTypeList"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
          </div>
        </div>
        <!-- end select first -->

        <!-- select second -->
        <div class="my-2">
          <div class="d-flex justify-start">
            <div style="width: 80px">
              <h6>チェックイン月</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                :items="month"
                v-model="fromMonth"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <div style="width: 80px">
              <h6>チェックイン日</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                :items="listDateFrom"
                v-model="fromDate"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <div style="width: 80px">
              <h6>チェックアウト月</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.checkTime"
                :items="month"
                v-model="toMonth"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <div style="width: 80px">
              <h6>チェックアウト日</h6>
              <v-select
                class="mr-5"
                dense
                :rules="rules.required"
                :items="listDateTo"
                v-model="toDate"
                item-text="name"
                item-value="id"
                :disabled="!canChangeCondition"
              ></v-select>
            </div>
            <v-btn class="btn-crm-primary" @click="filter" v-if="step === 1">
              予約状況リストを表示
            </v-btn>
          </div>
        </div>
        <!-- end select second -->
      </v-form>

      <template v-if="step===2">
        <CreateNewVMContractV2
          :contractId="contractId"
          :room-type-id="roomtypeId"
          :from-year="years"
          :years="numYear"
          :from-month="fromMonth"
          :from-date="fromDate"
          :to-month="toMonth"
          :to-date="toDate"
          :tentative="tentative"
          @reset="reset"
          @created="created"
          @close="handleInventrySelectClose"
        />
      </template>

      <template v-if="showVmStats && step === 1">
        <VmUsageStats
          :room-type-id="roomtypeId"
          :from-year="years"
          :years="numYear"
          :from-month="fromMonth"
          :from-date="fromDate"
          :to-month="toMonth"
          :to-date="toDate"
        />
      </template>
    </v-dialog>
  </div>
</template>
<script>
import CreateContractMixin from './CreateContract.mixin'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CreateNewVMContractV2 from './CreateNewVMContractV2.vue';
import VmUsageStats from './VmUsageStats';
import moment from 'moment';

export default {
  components: { CreateNewVMContractV2, VmUsageStats },
  mixins: [CreateContractMixin],
  data() {
    return {
      listDateFrom: [],
      listDateTo: [],
      idTmp: null,
      rules: {
        required: [(v) => !!v || '必須項目'],
        checkTime: [
          (v) => !!v || '必須項目',
        ],
      },
      showVmStats: false,
      step: 1,
      autoSelectRoomTypeId: null
    };
  },
  props: {
    visible: Boolean,
    contractId: Number,
    tentative: Boolean,
    existingContract: Object
  },
  computed: {
    ...mapGetters([
      'roomtypelistTemContract',
      'facilityIdTemContract',
      'yearsTemContract',
      'fromMonthTemContract',
      'fromDateTemContract',
      'toMonthTemContract',
      'toDateTemContract',
      'roomtypeIdTemContract',
      'stayDateTemContract',
      'numYearTemContract',
    ]),

    years: {
      get() {
        return this.yearsTemContract;
      },
      set(value) {
        this.fromMonth = null
        this.fromDate = null
        this.toMonth = null
        this.toDate = null
        this.listDateFrom = []
        this.listDateTo = []
        this.setYearsTemContract(value);
      },
    },

    numYear: {
      get() {
        return this.numYearTemContract;
      },
      set(value) {
        this.setNumYearTemContract(value);
      },
    },

    fromMonth: {
      get() {
        return this.fromMonthTemContract;
      },
      set(value) {
        this.listDateFrom = this.getNumDaysMonth(this.years, value)
        this.setFromMonthTemContract(value);
      },
    },

    fromDate: {
      get() {
        return this.fromDateTemContract;
      },
      set(value) {
        this.setFromDateTemContract(value);
      },
    },

    toMonth: {
      get() {
        return this.toMonthTemContract;
      },
      set(value) {
        this.listDateTo= this.getNumDaysMonth(this.years, value)
        this.setToMonthTemContract(value);
      },
    },

    toDate: {
      get() {
        return this.toDateTemContract;
      },
      set(value) {
        this.setToDateTemContract(value);
      },
    },

    roomtypeId: {
      get() {
        return this.roomtypeIdTemContract;
      },
      set(value) {
        this.setRoomtypeIdTemContract(value);
      },
    },

    facilityId: {
      get() {
        return this.facilityIdTemContract;
      },
      set(value) {
        if (value !== null) {
          this.setFacilityIdTemContract(value);
          this.getRoomtypelistTemContract(this.facilityId);
        }
      },
    },

    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    dialogWidth() {
      return this.step === 1 ? '1500px' : '700px'
    },
    canChangeCondition() {
      return this.step === 1 || !this.tentative
    },
    checkNullInput() {
      if (
        this.years &&
        this.numYear &&
        this.fromMonth &&
        this.fromDate &&
        this.toMonth &&
        this.toDate &&
        this.roomtypeId &&
        this.facilityId   
      )
        return false;
      else return true;
    },
  },
  watch: {
    tentative: {
      immediate: true,
      handler(v) {
        this.step = v ? 1 : 2; // the non-tentative should start from step 2
      }
    }, 
    existingContract: {
      immediate: true,
      handler (contract) {
        if (contract && contract.rights.length) {
          // set the parameters based on the existing contract
          const firstRight = contract.rights[0];
          this.years = firstRight.year;
          this.numYear = contract.rights.length;
          const booking = firstRight.bookingGroup.bookings[0];
          this.facilityId = contract.vmRoomType.facility.id;
          this.roomtypeId = contract.vmRoomType.id;
          if (booking) {
            // booking should always exist but just in case do this in if 
            const checkInDate = new Date(booking.checkInDate)
            this.fromMonth = checkInDate.getMonth() + 1;
            this.fromDate = checkInDate.getDate();

            const lastDate = new Date(booking.checkOutDate)
            // lastDate.setDate(lastDate.getDate() - 1)
            this.toMonth = lastDate.getMonth() + 1;
            this.toDate = lastDate.getDate();
          }
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'getRoomtypelistTemContract',
    ]),
    ...mapMutations([
      'setFacilityIdTemContract',
      'setFromMonthTemContract',
      'setFromDateTemContract',
      'setToMonthTemContract',
      'setToDateTemContract',
      'setRoomtypeIdTemContract',
      'setYearsTemContract',
      'setNumYearTemContract',
      'setStayDateTemContract',
      'setStateSelectedReservationRooms',
    ]),

    reset() {
      this.closeDialog();
      this.$emit('reset');
    },

    created() {
      this.closeDialog();
      this.$emit('created');
    },


    getNumDaysMonth(year, month){
      const arr = []
      if(year && month){
        if(month.length === 1){
          month = "0" + month
        }
        const date = moment(year + "-" + month, "YYYY-MM").daysInMonth()
        for(let i = 1; i<= date; i++){
          arr.push(i)
        }
      }
      return arr
    }, 

    async filter() {
      if (this.$refs.form.validate()) {
        this.showVmStats = true
      }
    },

    formatDate(date) {
      const month = date.toString().substr(5, 2);
      const day = date.toString().substr(8, 2);
      return month + '月' + day + '日';
    },

    closeDialog() {
      this.showVmStats = false;
      this.$emit('close');
    },
    handleInventrySelectClose() {
      if (!this.tentative) {
        // should close the whole dialog instead
        this.closeDialog()
      } else {
        this.step = 1
      }
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
h4 {
  color: #444444;
  font-size: 14px;
  font-weight: 500;
}
h6 {
  color: #000 !important;
  font-size: 9px;
  font-weight: 500;
}
.t-btn--prm {
  width: 110px !important;
  font-size: 14px !important;
}
::v-deep {
  .v-dialog {
    width: 1700px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
    .v-select__selection--comma {
      color: #444 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }
  .v-data-table-header {
    th {
      font-size: 14px !important;
      color: #757575 !important;
      font-weight: bold;
      .trtwo {
        td {
          font-size: 12px !important;
        }
      }
    }
  }
  tbody {
    tr {
      th {
        font-size: 16px !important;
        color: #757575 !important;
        font-weight: 400 !important;
      }
      td {
        span {
          font-size: 16px !important;
        }
      }
    }
  }
  td {
    word-break: keep-all !important;
    padding: 0 5px;
    text-align: center;
    min-width: 50px;
  }
  th {
    border-right: 1px solid #dedddf;
  }
  .v-data-footer {
    display: none !important;
  }
  .text-8px {
    font-size: 9px !important;
  }
}
</style>
